<template>
  <v-app>
    <v-overlay :value="drawer" z-index="4"> </v-overlay>
    <v-navigation-drawer v-model="drawer" app temporary clipped hide-overlay>
      <v-list nav>
        <!-- Agregando el el item de home directamente  -->
        <v-list-item v-if="user" @click="redirectToHomeView">
          <v-list-item-icon>
            <v-icon color="#80CBC4">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div v-for="item in filteredItems" :key="item.title">
          <v-list-item link :to="item.link">
            <template v-slot:[`item[3]`]="{ item }">
              <div v-if="user.supervisor">{{ item }}</div>
            </template>
            <v-list-item-icon>
              <v-icon color="#80CBC4">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- Nueva lista desplegable -->
        <!-- <div
          v-if="
            (user && secciones.includes('ADMINISTRADOR')) ||
            secciones.includes('reportes')
          "
        >
          <v-list-group color="grey darken-2">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="custom-list-item">
                  <v-icon class="custom-icon-color mr-7" left
                    >mdi-file-chart-outline</v-icon
                  >
                  Reportes
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon, link], i) in reportes"
              :key="i"
              link
              :to="link"
              class="custom-list-item"
            >
              <v-list-item-icon>
                <v-icon color="#80CBC4" v-text="icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title class="custom-list-item">
                {{ title }}</v-list-item-title
              >
            </v-list-item>
          </v-list-group>
        </div> -->

        <v-list-item v-if="user" href="javascript:void(0)" @click="logout">
          <v-list-item-icon>
            <v-icon color="#80CBC4">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left :color="navbarColor" elevation="0">
      <v-toolbar-title class="logo-title">
        <div class="logo-container">
          <img
            :src="
              navbarColor === 'white'
                ? require('@/assets/logo.png')
                : require('@/assets/logo.png')
            "
            height="50"
            class="logo-image invert-image"
          />
          <span
            :style="{ color: navbarColor === 'white' ? 'teal lighten-3' : '' }"
            class="logo-text"
            >CENTRAL EXPRESS</span
          >
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="#80CBC4"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "NavBar",
  components: {},

  data: () => ({
    navbarColor: "transparent",
    titleColor: "#80CBC4",
    imgSrc: "@/assets/logo.png",
    componentKey: 0,
    drawer: null,
    secciones: [],
    items: [
      // {
      //   title: "Inicio",
      //   icon: "mdi-home-outline",
      //   link: "/home",
      //   seccion: "inicio",
      // },
      // {
      //   title: "Usuarios",
      //   icon: "mdi-account-group",
      //   link: "/usuarios",
      //   seccion: "usuarios",
      // },
      {
        title: "Usuarios",
        icon: "mdi-account-group",
        link: "/usuarios",
        seccion: "usuarios",
      },
      {
        title: "Faltantes",
        icon: "mdi-magnify-close",
        link: "/faltantes",
        seccion: "faltantes",
      },
      {
        title: "Pedidos",
        icon: "mdi-file-document-multiple-outline",
        link: "/pedidos",
        seccion: "pedidos",
      },
      {
        title: "Envíos",
        icon: "mdi-truck-outline",
        link: "/envios",
        seccion: "envios",
      },
      {
        title: "Clientes",
        icon: "mdi-account-heart",
        link: "/clientes",
        seccion: "clientes",
      },
      {
        title: "Mis Clientes",
        icon: "mdi-account-star",
        link: "/mis_clientes",
        seccion: "mis_clientes",
      },
      {
        title: "Productos",
        icon: "mdi-shipping-pallet",
        link: "/productos",
        seccion: "productos",
      },
      /*{
        title: "Perfiles",
        icon: "mdi-face-man-profile",
        link: "/perfiles",
        seccion: "perfiles",
      },*/
      {
        title: "Cuenta",
        icon: "mdi-account-outline",
        link: "/cuenta",
        seccion: "cuenta",
      },
      {
        title: "Reportes",
        icon: "mdi-file-chart-outline",
        link: "/reportes",
        seccion: "reportes",
      },
      {
        title: "Precios y Existencias",
        icon: "mdi-currency-usd",
        link: "/precios_existencias",
        seccion: "precios_existencias",
      },
      {
        title: "Visitas",
        icon: "mdi-car-side",
        link: "/visitas",
        seccion: "visitas",
      },
      {
        title: "Imágenes",
        icon: "mdi-image-outline",
        link: "/imagenes",
        seccion: "imagenes",
      },
      {
        title: "Editar Laboratorios",
        icon: "mdi-file-document-edit-outline",
        link: "/editar_laboratorios",
        seccion: "laboratorios",
      },
      {
        title: "Editar JSON",
        icon: "mdi-code-json",
        link: "/editar_json",
        seccion: "editar_json",
      },
    ],
    itemsOut: [
      { title: "Iniciar Sesión", icon: "mdi-login", link: "/" },
      { title: "Acerca de ", icon: "mdi-help-box", link: "/about" },
    ],
    reportes: [
      ["Stock", "mdi-chart-bar", "/stock"],
      ["Precios y Existencias", "mdi-currency-usd", "/precios_existencias"],
      ["Compras", "mdi-storefront-check", "/compras"],
      ["Lotes", "mdi-package-variant-closed", "/lotes"],
      // ["Ventas", "mdi-table", "/ventas"],
      // ["Reporte de pedidos", "mdi-file-document", "/reporte_pedidos"],
      ["Tabla pivote", "mdi-table-pivot", "/tabla_pivote"],
    ],
  }),
  methods: {
    redirectToHomeView() {
      if (this.$route.path !== "/home") {
        this.$router.push("/home");
      } else {
        console.warn("Ya estás en la vista de inicio");
      }
      //this.$router.push("/home");
    },
    redirectToStockView() {
      // Redirigir a la vista de stock
      // Aquí puedes usar tu enrutador o cualquier método de redirección que estés utilizando en tu aplicación
      // Por ejemplo:
      this.$router.push("/stock");
    },
    redirectToPreciosExistenciasView() {
      this.$router.push("/precios_existencias");
    },
    redirectToComprasView() {
      this.$router.push("/compras");
    },
    scrollListener() {
      this.navbarColor = window.scrollY > 50 ? "white" : "transparent";
    },
    async logout() {
      localStorage.removeItem("access_token");
      this.$store.dispatch("user", null);
      // Llama a la acción para limpiar la información del usuario cuando sea necesario
      this.$store.dispatch("clearUserData");
      this.$router.push("/");
    },
    async getMyInfo() {
      const response = await axios.get("usuarios/");
      this.$store.dispatch("user", response.data);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async getSecciones() {
      const response = await axios.get("/usuarios/get_secciones");
      this.secciones = response.data.secciones;
    },
    removeAccents(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
  },
  mounted() {
    this.forceRerender();

    //evitar redundancia de pagina
    this.handleRedundantNavigation();
  },
  computed: {
    ...mapGetters(["user"]),

    filteredItems() {
      if (!this.user) {
        return this.itemsOut;
      }

      if (this.secciones.includes("ADMINISTRADOR")) {
        return this.items; // Si el array contiene "administrador", se retornan todos las secciones al navbar
      }

      const arraySecciones = this.items.filter(
        (
          item //Se filtran las secciones disponibles en el array de items
        ) => this.secciones.includes(item.seccion)
      );

      return arraySecciones;
    },

    // filteredItems() {
    //   if (!this.user) {
    //     return this.itemsOut;
    //   }

    //   const titlesToMatch = this.secciones; // Array con los títulos a filtrar
    //   const normalizedTitlesToMatch = titlesToMatch.map((title) =>
    //     this.removeAccents(title).toLowerCase()
    //   );

    //   console.log(normalizedTitlesToMatch);
    //   console.log(this.items);

    //   if (normalizedTitlesToMatch.includes("administrador")) {
    //     // let nuevoArray = this.items.filter(
    //     //   (elemento) => !elemento.title.includes("Stock")
    //     // );
    //     return this.items; // Si el array contiene "administrador", se retornan todos los elementos de items
    //   }

    //   return this.items.filter((item) => {
    //     const normalizedTitle = this.removeAccents(item.title).toLowerCase();
    //     const titleToMatch = normalizedTitlesToMatch.find((title) => {
    //       return title === normalizedTitle;
    //     });

    //     if (titleToMatch) {
    //       if (titleToMatch === "visitas") {
    //         return this.user.supervisor;
    //       }
    //       return true;
    //     }
    //     return false; // Si el título no coincide, se excluye del resultado filtrado
    //   });
    // },

    // filteredItems() {
    //   if (!this.user) {
    //     return this.itemsOut;
    //   }
    //   const titlesToMatch = this.secciones; // Array con los títulos a filtrar
    //   return this.items.filter((item) => {
    //     const lowercaseTitle = item.title.toLowerCase();
    //     if (titlesToMatch.includes(lowercaseTitle)) {
    //       if (lowercaseTitle === "visitas") {
    //         return this.user.supervisor;
    //       }
    //       return true;
    //     }
    //     return false; // Si el título no coincide, se excluye del resultado filtrado
    //   });
    // },

    // filteredItems() {
    //   if (!this.user) {
    //     return this.itemsOut;
    //   }
    //   return this.items.filter((item) => {
    //     if (item.title === "Visitas") {
    //       return this.user.supervisor;
    //     }
    //     return true;
    //   });
    // },
  },
  async created() {
    this.getMyInfo();
    window.addEventListener("scroll", this.scrollListener);
    this.getSecciones();
    this.$store.dispatch("fetchUserData");
  },
};
</script>

<style>
.custom-icon-color {
  color: #80cbc4 !important;
}

.custom-list-item {
  color: black !important;
}

.v-list-group .v-list-item .v-list-item__content {
  color: inherit !important;
}

.v-list-group .v-list-item .v-list-item__title {
  color: inherit !important;
}

.v-list-group--active .v-list-item .v-list-item__content {
  color: #80cbc4 !important;
}

.v-list-group--active .v-list-item .v-list-item__title {
  color: black !important;
}

.custom-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-panel {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: auto !important;
}

.icon {
  margin-right: 8px;
}

.custom-header {
  font-size: 16px;
  cursor: pointer;
}

.no-highlight-link {
  color: inherit !important;
  text-decoration: none !important;
  cursor: pointer;
}

.logo-title {
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-text {
  color: #80cbc4;
  font-family: "Sofia Sans Condensed", sans-serif;
  font-size: 36px;
}
</style>
