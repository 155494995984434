<template>
  <v-container fluid>
    <v-card-title class="teal lighten-3 accent-4 white--text">
      <v-row>
        <v-col cols="12" md="3" class="mt-4"> Pedidos Abiertos </v-col>

        <!-- <v-spacer></v-spacer> -->
        <v-col cols="12" md="4">
          <v-text-field
            @input="filtrarItems"
            v-model="search"
            append-icon="mdi-magnify"
            label="Búsqueda"
            single-line
            hide-details
          ></v-text-field>
        </v-col>

        <!-- <v-spacer /> -->
        <v-col cols="12" md="3">
          <v-select
            @input="filtrarItems"
            label="Filtrar por estado"
            v-model="filtroEstado"
            :items="listaEstados"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2" class="mt-4">
          <v-btn small @click="dialogImpresion = true"> Imprimir </v-btn>
        </v-col>
        <!-- dialog para seleccionar el tipo de impresion -->
        <v-dialog v-model="dialogImpresion" max-width="500px">
          <v-card>
            <v-card-title> Formato de impresión: </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn
                    @click="abrirVistaImpresionEtiquetas"
                    color="primary"
                    small
                    dark
                  >
                    Etiquetas
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="dialogOpcionesTabla = true"
                    color="primary"
                    small
                    dark
                  >
                    Tabla
                  </v-btn>
                  <!-- BOTON NUEVA TABLA -->
                  <!--
                  <v-btn
                    class="ml-5"
                    @click="dialogOpcionesTablaR = true"
                    color="primary"
                    small
                    dark
                    >Responsiva</v-btn
                  >
                -->
                </v-col>
              </v-row>
              <v-row>
                <div class="text-h6 mt-4 ml-3" style="color: black">
                  Descargar:
                </div>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    @click="verificarLocalizaciones"
                    color="primary"
                    small
                    dark
                  >
                    CSV
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- dialog para seleccionar opciones de impresion de la tabla de pedidos -->
        <v-dialog v-model="dialogOpcionesTabla" max-width="500px" scrollable>
          <v-card>
            <v-card-title>
              Opciones de impresión para la tabla de pedidos
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 400px">
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxDia"
                    :label="'Imprimir solo los pedidos del día y sin día asigando'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxLatitudLongitud"
                    label="Ocultar latitud y longitud"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-subheader>Imprimir pedidos con estado</v-subheader>
              <v-row class="mt-4 mx-2" dense>
                <v-select
                  v-model="estadosSelected"
                  :items="estadosPedidos"
                  label="Estados"
                  multiple
                  chips
                  deletable-chips
                ></v-select>
              </v-row>
              <!-- <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Pedido creado"
                    value="Pedido creado"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Revisión de crédito"
                    value="Revisión de crédito"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Confirmación"
                    value="Confirmación"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Facturación"
                    value="Facturación"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Prepración en almacén"
                    value="Preparación en almacén"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Preparado"
                    value="Preparado"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="En ruta de entrega"
                    value="En ruta de entrega"
                  ></v-checkbox>
                </v-col>
              </v-row> -->
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" text @click="abrirVistaImpresionTabla">
                Imprimir
              </v-btn>
              <v-btn color="primary" text @click="dialogOpcionesTabla = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog para seleccionar opciones de impresion de la tabla de pedidos -->
        <v-dialog v-model="dialogOpcionesTablaR" max-width="500px" scrollable>
          <v-card>
            <v-card-title>
              Opciones de impresión para la tabla de pedidos
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 400px">
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxDia"
                    :label="'Imprimir solo los pedidos del día y sin día asigando'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxZona"
                    :label="'Imprimir los pedidos con zona'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxPedidosSeleccionados"
                    :label="'Imprimir solo los pedidos seleccionados'"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-subheader>Imprimir pedidos con estado</v-subheader>
              <v-row class="mt-4 mx-2" dense>
                <v-select
                  v-model="estadosSelected"
                  :items="estadosPedidos"
                  label="Estados"
                  multiple
                  chips
                  deletable-chips
                ></v-select>
              </v-row>

              <v-subheader>Imprimir pedidos con mensajero:</v-subheader>
              <v-row class="mt-4 mx-2" dense>
                <!--select para seleccionar un mensajero -->
                <v-select
                  label="Mensajero"
                  v-model="mensajeroSelected"
                  :items="mensajerosImpresion"
                  item-text="nombre"
                  item-value="nombre"
                ></v-select>
              </v-row>
              <!-- <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Pedido creado"
                    value="Pedido creado"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Revisión de crédito"
                    value="Revisión de crédito"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Confirmación"
                    value="Confirmación"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Facturación"
                    value="Facturación"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Prepración en almacén"
                    value="Preparación en almacén"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="Preparado"
                    value="Preparado"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="checkboxesEstados"
                    label="En ruta de entrega"
                    value="En ruta de entrega"
                  ></v-checkbox>
                </v-col>
              </v-row> -->
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" text @click="abrirVistaImpresionTablaR">
                Imprimir
              </v-btn>
              <v-btn color="primary" text @click="dialogOpcionesTablaR = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Dialog para mostrar los pedidos sin localizacion -->
        <v-dialog
          v-model="dialogPedidosSinLocalizacion"
          max-width="500px"
          scrollable
        >
          <v-card>
            <v-card-title>
              Pedidos sin localización
              <v-icon class="ml-2" large color="orange">mdi-alert</v-icon>
            </v-card-title>
            <v-subheader>
              Los siguientes pedidos no se agregarán al archivo debido a que no
              cuentan con localización
            </v-subheader>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headersAvanzarPedidos"
                :items="pedidosSinLocalizacion"
                hide-default-header
                hide-default-footer
                :items-per-page="-1"
                dense
              ></v-data-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="exportToCSV(pedidosConLocalizacion)"
              >
                Aceptar
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="dialogPedidosSinLocalizacion = false"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-col cols="12" md="auto">
          <v-btn
            small
            color="primary"
            @click="dialogComentarios = !dialogComentarios"
          >
            Nuevos comentarios
          </v-btn>
        </v-col>
        <v-col cols="12" md="auto">
          <v-btn small color="primary" @click="verCantidadPedidos">
            Ver cantidad por estado
          </v-btn>
        </v-col>
        <!--Ver formato de impresion responsiva-->
        <!--
        <v-col cols="12" md="auto">
          <v-btn small color="primary" @click="mostrarFormatoGuardados">
            Ver Formatos Guardados</v-btn
          >
        </v-col>
        -->
        <v-dialog v-model="dialogoFormatos" max-width="600px">
          <v-card>
            <v-card-title>Formatos de Impresión Guardados</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(formato, index) in formatosImpresionGuardados.slice(
                      0,
                      formatosVisibles
                    )"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ formato.fecha }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <b>Mensajero:</b> {{ formato.mensajero }} |
                        <b>Pedidos:</b> {{ formato.numeroPedidos }} |
                        <b>Total:</b> ${{
                          formato.total
                            ? formato.total
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : "0.00"
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="verFormatoGuardado(formato)">
                        <v-icon color="primary">mdi-eye</v-icon>
                        <!-- Ícono para ver el formato -->
                      </v-btn>
                      <v-btn icon @click="eliminarFormato(index)">
                        <v-icon color="primary">mdi-delete</v-icon>
                        <!-- Ícono para ver el formato -->
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-btn
                v-if="formatosImpresionGuardados.length > formatosVisibles"
                text
                small
                color="primary"
                @click="mostrarMasFormatos"
              >
                Mostrar más
              </v-btn>
              <v-btn
                v-if="mostrarTodos"
                text
                small
                color="primary"
                @click="ocultarFormatos"
              >
                Ocultar
              </v-btn>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialogoFormatos = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog para seleccionar un periodo de comentarios -->
        <v-dialog v-model="dialogComentarios" max-width="500px">
          <v-card>
            <v-card-title> Ver comentarios nuevos desde hace: </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn
                    @click="getComentariosNuevos(1)"
                    color="primary"
                    x-small
                    dark
                  >
                    15 minutos
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getComentariosNuevos(2)"
                    color="primary"
                    x-small
                    dark
                  >
                    1 hora
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getComentariosNuevos(3)"
                    color="primary"
                    x-small
                    dark
                  >
                    4 horas
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getComentariosNuevos(4)"
                    color="primary"
                    x-small
                    dark
                  >
                    8 horas
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- dialog para ver la cantidad de pediddos por estado -->
        <v-dialog
          fullscreen
          transition="dialog-bottom-transition"
          v-model="dialogPedidos"
        >
          <v-card>
            <v-container>
              <v-btn
                class="close-btn"
                icon
                color="blue darken-1"
                text
                @click="dialogPedidos = !dialogPedidos"
              >
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
              <v-card-title>Cantidad de pedidos</v-card-title>
              <v-card-text>
                <apexchart
                  :options="chartOptions"
                  :series="chartSeries"
                  type="bar"
                  height="350"
                ></apexchart>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- <v-row>
          <v-btn small @click="abrirVistaImpresion"> Imprimir </v-btn>
        </v-row> -->
    </v-card-title>
    <v-tabs v-model="selectedTab" color="teal lighten-3 accent-4" left>
      <v-tab
        v-for="(sucursal, index) in itemsSucursalesDisponibles"
        :key="index"
      >
        <div v-if="sucursal.sucursal === 'LaPaz'">La Paz</div>
        <div v-else>
          {{ sucursal.sucursal }}
        </div>
      </v-tab>
      <v-tab-item
        :disabled="true"
        v-for="(sucursal, index) in itemsNombre"
        :key="index"
      >
        <v-data-table
          :headers="headers"
          :items="selectedItems"
          :search="search"
          v-model="selectedPedidos"
          show-select
          checkbox-color="blue darken-1"
          :footer-props="{
            'items-per-page-text': 'Pedidos por página',
            'items-per-page-all-text': 'Todos',
          }"
          :header-props="{ 'sort-by-text': 'Ordenar por' }"
          :item-class="itemRowBackgroundPrioridad"
          no-data-text="No se encontraron pedidos"
          no-results-text="No se encontraron pedidos"
          @dblclick:row="selectPedido"
          @current-items="currentItems"
        >
          <!-- Columna de Lista plegable de productos -->
          <template v-slot:[`item.lista_productos`]="{ item }">
            <v-list-group v-bind:class="itemRowBackground(item)">
              <template v-slot:activator> </template>
              <v-list-item
                v-for="producto in item.productos.lista_productos"
                :key="producto.codigo"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      producto.codigo +
                      " " +
                      producto.nombre +
                      ": " +
                      producto.cantidad
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <!-- Columna de total -->
          <template v-slot:[`item.total`]="{ item }">
            {{ "$" + formatoPrecio(item.total) }}
          </template>
          <!-- Columna de antiguedad -->
          <template v-slot:[`item.fecha_creacion`]="{ item }">
            {{ getAntiguedad(item.fecha_creacion) }}
          </template>
          <!-- Columna de discrepancias -->
          <template v-slot:[`item.discrepancias`]="{ item }">
            <div v-if="item.discrepancias === 'Sin discrepancias'">
              Sin discrepancias
            </div>
            <v-list-group v-else v-bind:class="itemRowBackground(item)">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-btn icon color="warning" text>
                      <v-icon dark> mdi-alert-outline </v-icon>
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="discrepancia in crearListaDiscrepancias(
                  item.discrepancias
                )"
                :key="discrepancia"
                no-action
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ discrepancia }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <!-- Columna de Lista plegable de facturas -->
          <template v-slot:[`item.facturas`]="{ item }">
            <div v-if="item.facturas.length != 0">
              <div v-if="item.facturas.length === 1">
                {{ item.facturas[0] }}
              </div>
              <v-list-group
                v-if="item.facturas.length > 1"
                v-bind:class="itemRowBackground(item)"
              >
                <template v-slot:activator> </template>
                <v-list-item v-for="factura in item.facturas" :key="factura">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ factura }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
            <div v-else>Sin facturas</div>
            <!-- <div v-if="item.facturas.length === 0">Sin facturaszz</div>
            <div v-else>{{ item.facturas[0] }}</div> -->
          </template>
          <!-- Columna de estado -->
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip color="primary" small>
              {{ item.estado }}
            </v-chip>
          </template>
          <!-- Columna de avanzar a: -->
          <template v-slot:[`item.avanzar_a`]="{ item }">
            <div
              v-if="item.estado === 'Cancelado' || item.estado === 'Entregado'"
            >
              N/A
            </div>

            <v-btn v-else @click="confirmarAvanzarPedido(item)" x-small>
              {{ getSiguienteEstado(item).text }}
            </v-btn>
          </template>
          <!-- Columna de acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <template v-if="arrayComentariosNuevos.includes(item.id)">
              <v-badge color="red" dot overlap>
                <AccionesPedido
                  :item="item"
                  :usuarios="usuarios"
                  @pedidoModificado="pedidoModificado"
                />
              </v-badge>
            </template>
            <template v-else>
              <AccionesPedido
                :item="item"
                :usuarios="usuarios"
                @pedidoModificado="pedidoModificado"
              />
            </template>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <!-- Dialogo para agregar mensajero -->
    <v-dialog v-model="dialogUsuariosGPS" max-width="500px">
      <v-card>
        <v-card-title> Agregar mensajero </v-card-title>
        <v-card-text>
          <v-select
            label="Mensajero"
            v-model="mensajero"
            :items="mensajeros"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="!mensajero"
            text
            @click="avanzarRutaEntrega"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog para confirmar el avance de un pedido -->
    <v-dialog v-model="dialogConfirmarAvanzar" max-width="500px">
      <v-card>
        <v-card-title> ¿Avanzar el pedido? </v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="avanzarPedido(pedidoAvanzar)">
            Avanzar
          </v-btn>
          <v-btn color="primary" text @click="dialogConfirmarAvanzar = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog para confirmar el avance de multiples pedidos -->
    <v-dialog
      v-model="dialogConfirmarAvanzarVarios"
      max-width="500px"
      scrollable
    >
      <v-card>
        <v-card-title>
          ¿Avanzar los pedidos?
          <v-spacer></v-spacer>
          <div v-if="selectedPedidos.length > 0">
            <v-btn
              v-if="selectedPedidos[0].estado === 'Preparado'"
              color="primary"
              x-small
              @click="agregarMensajeroVarios"
            >
              Agregar mensajero
            </v-btn>
          </div>
        </v-card-title>
        <div v-if="selectedPedidos.length > 0">
          <v-subheader v-if="selectedPedidos[0].estado === 'Facturación'"
            >Asegurate que todos los pedidos cuentan con una factura asociada
          </v-subheader>
        </div>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <!-- <v-list>
            <v-list-item v-for="item in selectedPedidos" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>{{
                  item.id + " - " + item.cliente
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->
          <v-data-table
            :headers="headersAvanzarPedidos"
            :items="selectedPedidos"
            hide-default-header
            hide-default-footer
            :items-per-page="-1"
            dense
          ></v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="avanzarVariosPedidos">
            Avanzar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialogConfirmarAvanzarVarios = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog para mostrar el progreso de multiples pedidos -->
    <v-dialog
      v-model="dialogProgresoAvanzarVarios"
      max-width="500px"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>Avanzando pedidos</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-data-table
            :headers="headersAvanzandoPedidos"
            :items="pedidosConEstadoSolicitud"
            hide-default-header
            hide-default-footer
            :items-per-page="-1"
            dense
          >
            <!-- Columna del estado de solicitud -->
            <template v-slot:[`item.solicitud`]="{ item }">
              <v-chip
                v-if="item.solicitud === 'En espera...'"
                color="primary"
                small
              >
                {{ item.solicitud }}
              </v-chip>
              <v-progress-circular
                v-if="item.solicitud === 'Procesando...'"
                :size="20"
                :width="3"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon
                v-if="item.solicitud === 'Completado'"
                color="green"
                size="20"
                >mdi-check-circle</v-icon
              >
              <v-icon
                v-if="item.solicitud === 'No completado'"
                color="red"
                size="20"
                >mdi-close-circle</v-icon
              >
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="AvanzandoPedidosBtn"
            text
            @click="dialogProgresoAvanzarVarios = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog para agregar mensajero individualmente avanzando varios pedidos-->
    <v-dialog v-model="dialogUsuariosGPSVarios" max-width="500px" scrollable>
      <v-card>
        <v-card-title> Agregar mensajero a los pedidos</v-card-title>
        <v-card-text>
          <div v-for="pedido in pedidosConEstadoSolicitud" :key="pedido.id">
            <p>{{ pedido.id }} - {{ pedido.cliente }}</p>
            <v-select
              v-model="pedido.nuevoMensajero"
              :items="mensajeros"
              label="Mensajero"
            ></v-select>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="avanzarRutaEntregaVarios"
            :disabled="!todosMensajerosSeleccionados"
            >Agregar</v-btn
          >
          <v-btn color="primary" text @click="dialogUsuariosGPSVarios = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog para agregar el mismo mensajero a varios pedidos preparados-->
    <v-dialog v-model="dialogAgregarMensajeroAll" max-width="500px" scrollable>
      <v-card>
        <v-card-title> Agregar mensajero a todos los pedidos</v-card-title>
        <v-card-text>
          <v-subheader>
            Este mensajero se asignará a todos los pedidos seleccionados
          </v-subheader>
          <v-select
            v-model="mensajeroParaVariosPreparados"
            :items="mensajeros"
            label="Mensajero"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="avanzarVariosPreparados"
            :disabled="!mensajeroParaVariosPreparados"
            >Agregar y avanzar</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="dialogAgregarMensajeroAll = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AccionesPedido from "@/components/AccionesPedido.vue";
import axios from "axios";
import { format, subMinutes, subHours } from "date-fns";
import VueApexCharts from "vue-apexcharts";
export default {
  props: ["items", "usuarios", "clientes"],
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
        },
        xaxis: {
          categories: [
            "Pedido creado",
            "Revisión de crédito",
            "Confirmación",
            "Facturación",
            "Prep. en almacén",
            "Preparado",
            "En ruta entrega",
          ],
        },
        fill: {
          opacity: 1,
        },
        colors: ["#42A5F5"],
        // colores que coinciden con los pedidos, por alguna razon se necesita agregar plotOptions

        // colors: [
        //   "#C8E6C9",
        //   "#D1C4E9",
        //   "#DCEDC8",
        //   "#B2DFDB",
        //   "#FFE0B2",
        //   "#BBDEFB",
        //   "#FFF9C4",
        // ],
        // plotOptions: {
        //   bar: {
        //     distributed: true,
        //   },
        // },
      },
      chartSeries: [
        {
          name: "Cantidad",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
      selectedTab: 0,
      pedidoAvanzar: null, //almacena el pedido que se va a avanzar en el dialog de dialogConfirmarAvanzar
      selectedPedidos: [], //almacena los pedidos seleccionados para avanzarlos juntos
      pedidosConEstadoSolicitud: [], //almacena los pedidos con estado de solicitud para mostrar su progreso
      search: "",
      filtroEstado: "",
      itemsActuales: [],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Lista de productos",
          value: "lista_productos",
          sortable: false,
        },
        {
          text: "Pago",
          value: "pago",
        },
        {
          text: "Vendedor",
          value: "vendedor",
        },
        {
          text: "Zona",
          value: "zona",
        },
        {
          text: "Antigüedad",
          value: "fecha_creacion",
        },
        {
          text: "Discrepancias",
          value: "discrepancias",
        },
        {
          text: "Facturas",
          value: "facturas",
        },
        {
          text: "Estado",
          value: "estado",
          filter: (value) => {
            if (!this.filtroEstado) return true;

            return value === this.filtroEstado;
          },
        },
        {
          text: "Avanzar a:",
          value: "avanzar_a",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
        },
      ],
      headersAvanzarPedidos: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
      ],
      headersAvanzandoPedidos: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Solicitud",
          value: "solicitud",
          align: "center",
        },
      ],
      ordenSucursales: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
      ordenEstados: [
        {
          text: "Pedido creado",
          value: "pedido_creado",
        },
        {
          text: "Confirmación",
          value: "confirmacion",
        },
        {
          text: "Facturación",
          value: "facturacion",
        },
        {
          text: "Preparación en almacén",
          value: "preparacion_en_almacen",
        },
        {
          text: "Preparado",
          value: "preparado",
        },
        {
          text: "En ruta de entrega",
          value: "en_ruta_de_entrega",
        },
        {
          text: "Entregado",
          value: "entregado",
        },
      ],
      listaEstados: [
        {
          text: "Sin filtro",
          value: "",
        },
        {
          text: "Pedido creado",
        },
        {
          text: "Revisión de crédito",
        },
        {
          text: "Confirmación",
        },
        {
          text: "Facturación",
        },
        {
          text: "Preparación en almacén",
        },
        {
          text: "Preparado",
        },
        {
          text: "En ruta de entrega",
        },
      ],
      estadosPedidos: [
        "Pedido creado",
        "Revisión de crédito",
        "Confirmación",
        "Facturación",
        "Preparación en almacén",
        "Preparado",
        "En ruta de entrega",
      ],
      estadosSelected: ["Preparado"],
      itemsSucursalesDisponibles: [],
      itemsFiltrados: [],
      itemsPedidos: this.items,

      checkboxDia: false,
      checkboxLatitudLongitud: false,
      checkboxPedidosSeleccionados: false,
      checkboxZona: false,
      // checkboxesEstados: ["Preparado", "En ruta de entrega"],

      dialogImpresion: false,
      dialogUsuariosGPS: false,
      dialogConfirmarAvanzar: false,
      dialogConfirmarAvanzarVarios: false,
      dialogProgresoAvanzarVarios: false,
      dialogUsuariosGPSVarios: false,
      dialogAgregarMensajeroAll: false,
      dialogPedidosSinLocalizacion: false,
      dialogOpcionesTabla: false,
      dialogOpcionesTablaR: false,
      AvanzandoPedidosBtn: true,

      pedidoEnRuta: null,
      mensajero: "",
      mensajeroParaVariosPreparados: "",
      mensajeros: [],
      mensajerosImpresion: [],
      mensajeroSelected: null,
      arrayComentariosNuevos: [],
      pedidosConLocalizacion: [],
      pedidosSinLocalizacion: [],
      dialogComentarios: false,
      dialogPedidos: false,

      dialogoFormatos: false,
      formatosImpresionGuardados: this.cargarFormatosGuardados(),
      formatosVisibles: 5,
      mostrarTodos: false,
      totalSumaCreados: 0,
      totalSumaRevisionCredito: 0,
      totalSumaConfirmacion: 0,
      totalSumaFacturacion: 0,
      totalSumaPreparadosAlmacen: 0,
      totalSumaPreparados: 0,
      totalSumaEnRuta: 0,
    };
  },
  methods: {
    mostrarFormatoGuardados() {
      this.dialogoFormatos = true;
    },

    cargarFormatosGuardados() {
      const formatos = localStorage.getItem("formatosImpresionGuardados");
      return formatos ? JSON.parse(formatos) : [];
    },

    guardarFormatosImpresion() {
      localStorage.setItem(
        "formatosImpresionGuardados",
        JSON.stringify(this.formatosImpresionGuardados)
      );
    },

    verFormatoGuardado(formato) {
      const ventana = window.open("", "_blank");
      ventana.document.write(formato.contenidoHTML);
      ventana.document.write(formato.contenidoTablaCreados);
      ventana.document.write(formato.contenidoTablaRevisionCredito);
      ventana.document.write(formato.contenidoTablaConfirmacion);
      ventana.document.write(formato.contenidoTablaFacturacion);
      ventana.document.write(formato.contenidoTablaPreparadosAlmacen);
      ventana.document.write(formato.contenidoTablaPreparados);
      ventana.document.write(formato.contenidoTablaEnRuta);
      ventana.document.write(formato.contenidoHTML2);
      ventana.document.close();
      //ventana.print();
    },

    calcularTotalDePedidos() {
      //calcular el total de los pedidos de cada estado

      return this.pedidos.reduce((total, pedido) => total + pedido.total, 0);
    },

    eliminarFormato(index) {
      this.formatosImpresionGuardados.splice(index, 1);
      this.guardarFormatosImpresion();
    },

    mostrarMasFormatos() {
      this.formatosVisibles += 5;
      this.mostrarTodos = true;
    },

    ocultarFormatos() {
      this.formatosVisibles = 5;
      this.mostrarTodos = false;
    },

    obtenerMensajeroConGps() {
      this.mensajerosImpresion = this.usuarios.filter(
        (usuario) => usuario.gps !== null
      );
    },

    selectPedido(event, rowInfo) {
      // Comprobamos si el objeto ya existe en el array
      const index = this.selectedPedidos.findIndex(
        (pedido) => pedido.id === rowInfo.item.id
      );

      if (index !== -1) {
        // Si el objeto ya existe, lo eliminamos del array
        this.selectedPedidos.splice(index, 1);
      } else {
        // Si no existe, lo agregamos al array
        this.selectedPedidos.push(rowInfo.item);
      }

      console.log(this.selectedPedidos);
    },
    agregarMensajeroVarios() {
      const mensajerosArray = this.usuarios.filter(
        (usuario) =>
          usuario.sucursal === this.selectedPedidos[0].sucursal &&
          usuario.gps !== null
      );
      console.log(mensajerosArray);

      this.mensajeros = mensajerosArray.map((mensajero) => ({
        text: mensajero.nombre,
        value: mensajero.id,
      }));

      this.dialogAgregarMensajeroAll = true;
    },
    async avanzarVariosPreparados() {
      console.log(this.mensajeroParaVariosPreparados);
      console.log("se avanzan los pedidos");
      // se le asigna el estado 'en espera...' a todos los pedidos. Ese estado se ira modificando comforme se avancen los pedidos
      const pedidosEstadoSolicitud = this.selectedPedidos.map((pedido) => {
        const pedidoEstadoSolicitud = { ...pedido };
        pedidoEstadoSolicitud.solicitud = "En espera...";
        return pedidoEstadoSolicitud;
      });
      this.pedidosConEstadoSolicitud = pedidosEstadoSolicitud;

      this.dialogConfirmarAvanzarVarios = false;
      this.dialogAgregarMensajeroAll = false;
      this.dialogProgresoAvanzarVarios = true;
      this.AvanzandoPedidosBtn = true;

      // for (let i = 0; i < this.pedidosConEstadoSolicitud.length; i++) {
      //   // Cambiar el estado del objeto en el array
      //   this.pedidosConEstadoSolicitud[i].solicitud = "Procesando...";

      //   // Simular solicitud al backend esperando 5 segundos
      //   await new Promise((resolve) => setTimeout(resolve, 500));

      //   // Cambiar el estado del objeto en el array
      //   this.pedidosConEstadoSolicitud[i].solicitud = "Completado";

      //   // Imprimir el estado actual después de cambiarlo
      //   console.log(
      //     `Estado cambiado para objeto con id ${this.pedidosConEstadoSolicitud[i].id}: ${this.pedidosConEstadoSolicitud[i].solicitud}`
      //   );
      // }

      // this.AvanzandoPedidosBtn = false; //El btn se activa de nuevo

      try {
        // Creamos un array de promesas utilizando map para hacer una solicitud por cada pedido
        const promesasSolicitudes = this.pedidosConEstadoSolicitud.map(
          async (pedido) => {
            pedido.solicitud = "Procesando...";
            try {
              const avanzado = await this.avanzarPedidoEnArrayPreparado(pedido);
              if (avanzado) {
                pedido.solicitud = "Completado";
              } else {
                pedido.solicitud = "No completado";
              }
            } catch (error) {
              console.error("Error al avanzar pedido:", error);
              pedido.solicitud = "Error";
            }
          }
        );

        // Usamos Promise.allSettled para esperar a que todas las promesas se resuelvan o rechacen
        const resultados = await Promise.allSettled(promesasSolicitudes);

        console.log(resultados);
        this.selectedPedidos = [];

        await this.getPedidos();
        await this.obtenerArregloSucursalesDisponibles();
        this.AvanzandoPedidosBtn = false; //El btn se activa de nuevo
        this.mensajeroParaVariosPreparados = "";
      } catch (error) {
        console.error("Error al avanzar pedidos:", error);
        this.AvanzandoPedidosBtn = false;
        this.mensajeroParaVariosPreparados = "";
      }
    },
    async avanzarRutaEntregaVarios() {
      console.log(this.pedidosConEstadoSolicitud);
      this.dialogUsuariosGPSVarios = false;
      this.dialogProgresoAvanzarVarios = true;
      this.AvanzandoPedidosBtn = true; // Deshabilitar el botón

      // se comprueba el siguiente estado de los pedidos, si es 'en ruta de entrega' se abre un dialog para seleccionar el mensajero para cada pedido
      const siguienteEstado = this.getSiguienteEstado(
        this.pedidosConEstadoSolicitud[0]
      );

      try {
        // Creamos un array de promesas utilizando map para hacer una solicitud por cada pedido
        const promesasSolicitudes = this.pedidosConEstadoSolicitud.map(
          async (pedido) => {
            // pedido.solicitud = "Procesando...";
            try {
              const avanzado = await this.avanzarPedidoEnArray(
                pedido,
                siguienteEstado
              );
              if (avanzado) {
                pedido.solicitud = "Completado";
              } else {
                pedido.solicitud = "No completado";
              }
            } catch (error) {
              console.error("Error al avanzar pedido:", error);
              pedido.solicitud = "Error";
            }
          }
        );

        // Usamos Promise.allSettled para esperar a que todas las promesas se resuelvan o rechacen
        const resultados = await Promise.allSettled(promesasSolicitudes);

        console.log(resultados);
        this.selectedPedidos = [];

        await this.getPedidos();
        await this.obtenerArregloSucursalesDisponibles();
      } catch (error) {
        console.error("Error al avanzar pedidos:", error);
      }

      this.AvanzandoPedidosBtn = false; // Habilitar el botón
    },
    async avanzarVariosPedidos() {
      console.log("se avanzan los pedidos");
      // se le asigna el estado 'en espera...' a todos los pedidos. Ese estado se ira modificando comforme se avancen los pedidos
      const pedidosEstadoSolicitud = this.selectedPedidos.map((pedido) => {
        const pedidoEstadoSolicitud = { ...pedido };
        pedidoEstadoSolicitud.solicitud = "En espera...";
        return pedidoEstadoSolicitud;
      });
      this.pedidosConEstadoSolicitud = pedidosEstadoSolicitud;

      // se comprueba el siguiente estado de los pedidos, si es 'en ruta de entrega' se abre un dialog para seleccionar el mensajero para cada pedido
      const siguienteEstado = this.getSiguienteEstado(
        pedidosEstadoSolicitud[0]
      );
      if (siguienteEstado.value == "en_ruta_de_entrega") {
        // Utilizar el método filter para obtener un nuevo array con usuarios cuya sucursal sea la del pedido
        const mensajerosArray = this.usuarios.filter(
          (usuario) =>
            usuario.sucursal === pedidosEstadoSolicitud[0].sucursal &&
            usuario.gps !== null
        );
        console.log(mensajerosArray);

        this.mensajeros = mensajerosArray.map((mensajero) => ({
          text: mensajero.nombre,
          value: mensajero.id,
        }));

        this.dialogConfirmarAvanzarVarios = false;
        this.dialogUsuariosGPSVarios = true;
      } else {
        this.dialogConfirmarAvanzarVarios = false;
        this.dialogProgresoAvanzarVarios = true;
        this.AvanzandoPedidosBtn = true;

        // for (let i = 0; i < this.pedidosConEstadoSolicitud.length; i++) {
        //   // Cambiar el estado del objeto en el array
        //   this.pedidosConEstadoSolicitud[i].solicitud = "Procesando...";

        //   // Simular solicitud al backend esperando 5 segundos
        //   await new Promise((resolve) => setTimeout(resolve, 500));

        //   // Cambiar el estado del objeto en el array
        //   this.pedidosConEstadoSolicitud[i].solicitud = "Completado";

        //   // Imprimir el estado actual después de cambiarlo
        //   console.log(
        //     `Estado cambiado para objeto con id ${this.pedidosConEstadoSolicitud[i].id}: ${this.pedidosConEstadoSolicitud[i].solicitud}`
        //   );
        // }

        try {
          // Creamos un array de promesas utilizando map para hacer una solicitud por cada pedido
          const promesasSolicitudes = this.pedidosConEstadoSolicitud.map(
            async (pedido) => {
              pedido.solicitud = "Procesando...";
              try {
                const avanzado = await this.avanzarPedidoEnArray(
                  pedido,
                  siguienteEstado
                );
                if (avanzado) {
                  pedido.solicitud = "Completado";
                } else {
                  pedido.solicitud = "No completado";
                }
              } catch (error) {
                console.error("Error al avanzar pedido:", error);
                pedido.solicitud = "Error";
              }
            }
          );

          // Usamos Promise.allSettled para esperar a que todas las promesas se resuelvan o rechacen
          const resultados = await Promise.allSettled(promesasSolicitudes);

          console.log(resultados);
          this.selectedPedidos = [];

          await this.getPedidos();
          await this.obtenerArregloSucursalesDisponibles();
          if (siguienteEstado.value === "entregado") {
            this.$emit("pedidoEntregado");
          }
          this.AvanzandoPedidosBtn = false; //El btn se activa de nuevo
        } catch (error) {
          console.error("Error al avanzar pedidos:", error);
          this.AvanzandoPedidosBtn = false;
        }
      }
    },
    /* Metodo que se utiliza en avanzarVariosPedidos dentro de un ciclo para ir avanzando varios pedidos uno tras otro, regresa true si se avanza el pedido y false si hubo un error
    esto para que se pueda mostrar un icono dependiendo si se avanzo el pedido o no */
    async avanzarPedidoEnArray(pedido, nuevoEstado) {
      /* Si el estado actual del pedido es revision de credito, su siguiente estado es confirmacion, 
      se hace directamente ya que el estado revision de credito no se encuentra en el orden de estados */
      if (pedido.estado === "Revisión de crédito") {
        try {
          const response = await axios.post(
            `/pedidos/avanzar_pedido?id_pedido=${pedido.id}&nuevo_estado=confirmacion`
          );
          console.log(response.data);
          //Se solicitan los pedidos para actualizar la tabla sin tener que recargar toda la view completa
          // await this.getPedidos();
          // await this.obtenerArregloSucursalesDisponibles();
          // if (nuevoEstado.value === "entregado") {
          //   this.$emit("pedidoEntregado");
          // }
          this.dialogConfirmarAvanzar = false;
          return true;
        } catch (error) {
          if (error.response.status === 401) {
            console.error(error.response);
          } else {
            console.error(error.response);
          }
          return false;
        }
      }
      //Si el siguiente estado del pedido es en_ruta_de_entrega, se tiene que agregar el mensajero en la solicitud
      else if (nuevoEstado.value == "en_ruta_de_entrega") {
        try {
          const response = await axios.post(
            `/pedidos/avanzar_pedido?id_pedido=${pedido.id}&nuevo_estado=en_ruta_de_entrega&mensajero_id=${pedido.nuevoMensajero}` //nuevoMensajero se asigna en dialogUsuariosGPSVarios
          );
          console.log(response.data);
          // alert("Pedido avanzado");
          // location.reload();
          //Se solicitan los pedidos para actualizar la tabla sin tener que recargar toda la view completa
          // await this.getPedidos();
          // await this.obtenerArregloSucursalesDisponibles();
          // if (nuevoEstado.value === "entregado") {
          //   this.$emit("pedidoEntregado");
          // }
          this.dialogConfirmarAvanzar = false;
          // this.$emit("pedidoModificado");
          return true;
        } catch (error) {
          if (error.response.status === 401) {
            console.error(error.response);
          } else {
            console.error(error.response);
          }
          this.dialogConfirmarAvanzar = false;
          return false;
        }
      } else {
        try {
          const response = await axios.post(
            `/pedidos/avanzar_pedido?id_pedido=${pedido.id}&nuevo_estado=${nuevoEstado.value}`
          );
          console.log(response.data);
          // alert("Pedido avanzado");
          // location.reload();
          //Se solicitan los pedidos para actualizar la tabla sin tener que recargar toda la view completa
          // await this.getPedidos();
          // await this.obtenerArregloSucursalesDisponibles();
          // if (nuevoEstado.value === "entregado") {
          //   this.$emit("pedidoEntregado");
          // }
          this.dialogConfirmarAvanzar = false;
          // this.$emit("pedidoModificado");
          return true;
        } catch (error) {
          console.error(error.response);
          this.dialogConfirmarAvanzar = false;
          return false;
        }
      }
    },
    async avanzarPedidoEnArrayPreparado(pedido) {
      //this.mensajeroParaVariosPreparado contiene el mensajero que se le asiganra a todos los pedidos seleccionados
      try {
        const response = await axios.post(
          `/pedidos/avanzar_pedido?id_pedido=${pedido.id}&nuevo_estado=en_ruta_de_entrega&mensajero_id=${this.mensajeroParaVariosPreparados}` //mensajeroParaVariosPreparado se asigna en dialogAgregarMensajeroAll
        );
        console.log(response.data);
        return true;
      } catch (error) {
        if (error.response.status === 401) {
          console.error(error.response);
        } else {
          console.error(error.response);
        }
        return false;
      }
    },
    confirmarAvanzarPedido(pedido) {
      // Comprobamos si el pedido que se quiere avanzar esta en el array de selectedPedidos
      const index = this.selectedPedidos.findIndex(
        (objeto) => objeto.id === pedido.id
      );

      // Si el pedido que se intenta avanzar existe en el array de selectedPedidos
      if (index !== -1) {
        //si hay mas de un pedido seleccionado se intentan avanzar juntos
        if (this.selectedPedidos.length > 1) {
          //se comprueba si todos los pedidos tienen el mismo estado
          if (this.pedidosMismoEstado(this.selectedPedidos)) {
            this.dialogConfirmarAvanzarVarios = true;
          }
          // al menos un pedido no tiene el mismo estado
          else {
            alert(
              "Todos los pedidos seleccionados deben tener el mismo estado para poder avanzarlos"
            );
          }
        }
        //si solo hay un pedido seleccionado se avanza normalmente
        else if (this.selectedPedidos.length === 1) {
          this.pedidoAvanzar = pedido;
          this.dialogConfirmarAvanzar = true;
        }
      }
      // Si no existe en el array de selectedPedidos, se intenta avanzar individualmente
      else {
        this.pedidoAvanzar = pedido;
        this.dialogConfirmarAvanzar = true;
      }
    },
    /* Verifica si todos los pedidos del array tienen el mismo estado */
    pedidosMismoEstado(pedidos) {
      // Si el array de pedidos está vacío, retornamos true
      if (pedidos.length === 0) {
        return true;
      }

      // Obtenemos el estado del primer pedido
      const primerEstado = pedidos[0].estado;

      // Verificamos si todos los estados son iguales al primer estado
      return pedidos.every((pedido) => pedido.estado === primerEstado);
    },
    currentItems: function (value) {
      this.itemsActuales = value;
      // console.log(this.itemsActuales);
    },
    filtrarItems() {
      const criterioBusqueda = this.search.toLowerCase();

      this.itemsFiltrados = this.itemsActuales.filter((item) => {
        // Aplica aquí tu lógica de filtrado según tus necesidades
        for (let prop in item) {
          const valor = item[prop];

          if (
            typeof valor === "string" &&
            valor.toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si alguna propiedad coincide, se incluye el item en los resultados
          } else if (
            typeof valor === "number" &&
            valor.toString().toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si el valor numérico coincide, se incluye el item en los resultados
          }
        }
        return false;
      });
    },
    async getComentarios() {
      let arrayFiltrado = [];

      if (this.itemsFiltrados.length === 0) {
        arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;
      } else {
        arrayFiltrado = this.itemsFiltrados;
      }

      try {
        // Creamos un array de promesas utilizando map para hacer una solicitud por cada pedido
        const promesasSolicitudes = arrayFiltrado.map(async (pedido) => {
          const response = await axios.get(
            `/varios/get_comentarios?id_objeto=${pedido.id}&tipo_objeto=pedido`
          );
          return response.data; // Aquí puedes retornar la data específica que necesitas del response
        });

        // Resolvemos todas las promesas utilizando Promise.all
        const resultados = await Promise.all(promesasSolicitudes);

        // con resultados.flat() haces que el array sea de nivel 1 para no tener todos los comentarios anidados
        // console.log(resultados.flat());
        return resultados.flat();
      } catch (error) {
        console.error("Error al obtener los comentarios:", error);
      }
    },
    async getLocalizacion(idCliente) {
      try {
        // Creamos un array de promesas utilizando map para hacer una solicitud por cada pedido

        const response = await axios.get(`/reportes/localizacion/${idCliente}`);
        return response.data; // Aquí puedes retornar la data específica que necesitas del response
      } catch (error) {
        console.error("Error al obtener localizacion:", error);
        return {
          latitud: "",
          longitud: "",
        };
      }
    },
    filtrarComentariosPorIdObjeto(idObjeto, arrayComentarios) {
      // Utilizamos el método 'filter' para obtener los comentarios que coinciden con 'id_objeto'
      const comentariosFiltrados = arrayComentarios.filter(
        (comentario) => comentario.id_objeto === idObjeto
      );

      return comentariosFiltrados;
    },
    async abrirVistaImpresionTabla() {
      let arrayFiltrado = [];
      this.dialogImpresion = false;
      this.dialogOpcionesTabla = false;

      // if (this.itemsFiltrados.length === 0) {
      //   arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;
      // } else {
      //   arrayFiltrado = this.itemsFiltrados;
      // }

      arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;

      console.log(arrayFiltrado);

      // Se obtienen las localizaciones de todos los pedidos
      const localizacionesCache = new Map(); // Map para almacenar localizaciones ya obtenidas

      // Funcion para solicitar la localizacion o obtenerla de la cache
      const getCachedLocalizacion = async (idCliente) => {
        if (!localizacionesCache.has(idCliente)) {
          // Si la localización no ha sido obtenida aún, hacer la solicitud
          const localizacionPromise = this.getLocalizacion(idCliente);
          localizacionesCache.set(idCliente, localizacionPromise); // Guardar la promesa temporalmente
          const localizacion = await localizacionPromise;
          // Actualizar el Map con la localización resuelta
          localizacionesCache.set(idCliente, {
            latitud: localizacion.latitud || "",
            longitud: localizacion.longitud || "",
            dia: localizacion.dia,
          });
        }
        // Usar la localización almacenada en el cache
        return localizacionesCache.get(idCliente);
      };

      const arrayLocalizaciones = await Promise.all(
        arrayFiltrado.map(async (item) => {
          const localizacion = await getCachedLocalizacion(item.idCliente);
          const nuevoItem = {
            id: item.id,
            latitud: localizacion.latitud,
            longitud: localizacion.longitud,
            dia: localizacion.dia,
            cliente: item.cliente,
            facturas: item.facturas,
            contado: item.contado,
            zona: item.zona,
            fecha_creacion: item.fecha_creacion,
            mensajero: item.mensajero,
            estado: item.estado,
          };
          return nuevoItem;
        })
      );

      // console.log(arrayLocalizaciones);

      let nuevoArray = [];

      // Filtrar los pedidos por dia actual si es que se requiere
      if (this.checkboxDia) {
        nuevoArray = arrayLocalizaciones.filter(
          (item) => item.dia === this.currentDay
        );
      } else {
        nuevoArray = arrayLocalizaciones;
      }

      // Separamos los pedidos por estado para mostrarlos en la tabla
      let pedidosCreados = [];
      let pedidosRevisionCredito = [];
      let pedidosConfirmacion = [];
      let pedidosFacturacion = [];
      let pedidosPreparadosAlmacen = [];
      let pedidosPreparados = [];
      let pedidosEnRuta = [];

      if (this.estadosSelected.includes("Pedido creado")) {
        pedidosCreados = nuevoArray.filter(
          (item) => item.estado === "Pedido creado"
        );
      }

      if (this.estadosSelected.includes("Revisión de crédito")) {
        pedidosRevisionCredito = nuevoArray.filter(
          (item) => item.estado === "Revisión de crédito"
        );
      }

      if (this.estadosSelected.includes("Confirmación")) {
        pedidosConfirmacion = nuevoArray.filter(
          (item) => item.estado === "Confirmación"
        );
      }

      if (this.estadosSelected.includes("Facturación")) {
        pedidosFacturacion = nuevoArray.filter(
          (item) => item.estado === "Facturación"
        );
      }

      if (this.estadosSelected.includes("Preparación en almacén")) {
        pedidosPreparadosAlmacen = nuevoArray.filter(
          (item) => item.estado === "Preparación en almacén"
        );
      }

      if (this.estadosSelected.includes("Preparado")) {
        pedidosPreparados = nuevoArray.filter(
          (item) => item.estado === "Preparado"
        );
      }

      if (this.estadosSelected.includes("En ruta de entrega")) {
        pedidosEnRuta = nuevoArray.filter(
          (item) => item.estado === "En ruta de entrega"
        );
      }

      // Crear una ventana emergente
      const ventana = window.open("", "_blank");

      // Construir el contenido HTML de la vista previa de impresión
      let contenidoTablaCreados = "";
      let contenidoTablaRevisionCredito = "";
      let contenidoTablaConfirmacion = "";
      let contenidoTablaFacturacion = "";
      let contenidoTablaPreparadosAlmacen = "";
      let contenidoTablaPreparados = "";
      let contenidoTablaEnRuta = "";
      const contenidoHTML = `
      <!doctype html>
      <html lang="es">

      <head>

          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Pedidos</title>
          <!-- Enlace al archivo CSS de Bootstrap -->
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"
              integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
          <style>
              @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap');
              @page { size: landscape; } 

              body {
                  font-family: Arial, sans-serif;
                  display: flex;
                  flex-direction: column;
                  height: 100vh;
                  margin: 0;
              }

              .content {
                  flex: 1;
              }

              .table-table {
                  width: 100%;
                  border-collapse: collapse;
                  font-size: 11px;
              }

              .tabla-th {
                  background-color: #f2f2f2;
                  padding: 8px;
                  text-align: left;
              }

              .tabla-td {
                  padding: 8px;
                  text-align: left;
              }

              .datos-table {
                  width: 100%;
                  border-collapse: collapse;
              }

              .datos-th {
                  padding: 8px;
                  font-size: 10px;
                  text-align: left;
              }

              .datos-td {
                  padding: 8px;
                  font-size: 14px;
                  text-align: left;
              }

              .comment-column {
                  width: 200px;
              }

              .comment-column-data {
                  width: 200px;
                  height: 50px;
              }

              @media print {
                  .tabla-th,
                  .tabla-th {
                      background-color: #f2f2f2 !important;
                  }
                  th, td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: left;
                  }
              }
          </style>
      </head>

      <body class="d-flex">

          <div class="d-flex flex-column">
              <table class="table-table table table-bordered table-sm">
                  <thead>
                      <tr>
                          <th class="tabla-th">ID</th>
                          <th class="tabla-th">Fecha pedido</th>
                          <th class="tabla-th">Cliente</th>
                          <th class="tabla-th">Facturas</th>
                          <th class="tabla-th">Contado</th>
                          <th class="tabla-th">Zona</th>
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : "<th class='tabla-th'>Latitud</th>"
                          }
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : "<th class='tabla-th'>Longitud</th>"
                          }
                          <th class="tabla-th">Dia</th>
                          <th class="tabla-th">Mensajero</th>
                          <th class="tabla-th">Entregado</th>
                          <th class="tabla-th ${
                            this.checkboxLatitudLongitud
                              ? " comment-column"
                              : ""
                          }">Comentarios</th>
                      </tr>
                  </thead>
                  <tbody>`;

      if (pedidosCreados.length > 0) {
        contenidoTablaCreados = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos creados</th>`
                          : `<th colspan="12">Pedidos creados</th>`
                      }
                    </tr>
                      ${pedidosCreados
                        .map(
                          (pedido) => `
                      <tr>
                          <td class="tabla-td">${pedido.id}</td>
                          <td class="tabla-td">${this.formatoFecha(
                            pedido.fecha_creacion
                          )}</td>
                          <td class="tabla-td">${pedido.cliente}</td>
                          <td class="tabla-td">${pedido.facturas}</td>
                          <td class="tabla-td">${
                            pedido.contado ? "Sí" : "No"
                          }</td>
                          <td class="tabla-td">${pedido.zona}</td>
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].latitud || ""
                                }</td>`
                          }
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].longitud || ""
                                }</td>`
                          }
                          <td class="tabla-td">${pedido.dia}</td>
                          <td class="tabla-td">${pedido.mensajero}</td>
                          <td class="tabla-td">${" "}</td>
                          <td class="tabla-td ${
                            this.checkboxLatitudLongitud
                              ? " comment-column-data"
                              : ""
                          }"> </td>
                      </tr>
                      `
                        )
                        .join("")}
                    `;
      }

      if (pedidosRevisionCredito.length > 0) {
        contenidoTablaRevisionCredito = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos en revisión de crédito</th>`
                          : `<th colspan="12">Pedidos en revisión de crédito</th>`
                      }
                    </tr>
                      ${pedidosRevisionCredito
                        .map(
                          (pedido) => `
                      <tr>
                          <td class="tabla-td">${pedido.id}</td>
                          <td class="tabla-td">${this.formatoFecha(
                            pedido.fecha_creacion
                          )}</td>
                          <td class="tabla-td">${pedido.cliente}</td>
                          <td class="tabla-td">${pedido.facturas}</td>
                          <td class="tabla-td">${
                            pedido.contado ? "Sí" : "No"
                          }</td>
                          <td class="tabla-td">${pedido.zona}</td>
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].latitud || ""
                                }</td>`
                          }
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].longitud || ""
                                }</td>`
                          }
                          <td class="tabla-td">${pedido.dia}</td>
                          <td class="tabla-td">${pedido.mensajero}</td>
                          <td class="tabla-td">${" "}</td>
                          <td class="tabla-td ${
                            this.checkboxLatitudLongitud
                              ? " comment-column-data"
                              : ""
                          }"> </td>
                      </tr>
                      `
                        )
                        .join("")}
                    `;
      }

      if (pedidosConfirmacion.length > 0) {
        contenidoTablaConfirmacion = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos en confirmación</th>`
                          : `<th colspan="12">Pedidos en confirmación</th>`
                      }
                    </tr>
                      ${pedidosConfirmacion
                        .map(
                          (pedido) => `
                      <tr>
                          <td class="tabla-td">${pedido.id}</td>
                          <td class="tabla-td">${this.formatoFecha(
                            pedido.fecha_creacion
                          )}</td>
                          <td class="tabla-td">${pedido.cliente}</td>
                          <td class="tabla-td">${pedido.facturas}</td>
                          <td class="tabla-td">${
                            pedido.contado ? "Sí" : "No"
                          }</td>
                          <td class="tabla-td">${pedido.zona}</td>
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].latitud || ""
                                }</td>`
                          }
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].longitud || ""
                                }</td>`
                          }
                          <td class="tabla-td">${pedido.dia}</td>
                          <td class="tabla-td">${pedido.mensajero}</td>
                          <td class="tabla-td">${" "}</td>
                          <td class="tabla-td ${
                            this.checkboxLatitudLongitud
                              ? " comment-column-data"
                              : ""
                          }"> </td>
                      </tr>
                      `
                        )
                        .join("")}
                    `;
      }

      if (pedidosFacturacion.length > 0) {
        contenidoTablaFacturacion = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos en facturación</th>`
                          : `<th colspan="12">Pedidos en facturación</th>`
                      }
                    </tr>
                      ${pedidosFacturacion
                        .map(
                          (pedido) => `
                      <tr>
                          <td class="tabla-td">${pedido.id}</td>
                          <td class="tabla-td">${this.formatoFecha(
                            pedido.fecha_creacion
                          )}</td>
                          <td class="tabla-td">${pedido.cliente}</td>
                          <td class="tabla-td">${pedido.facturas}</td>
                          <td class="tabla-td">${
                            pedido.contado ? "Sí" : "No"
                          }</td>
                          <td class="tabla-td">${pedido.zona}</td>
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].latitud || ""
                                }</td>`
                          }
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].longitud || ""
                                }</td>`
                          }
                          <td class="tabla-td">${pedido.dia}</td>
                          <td class="tabla-td">${pedido.mensajero}</td>
                          <td class="tabla-td">${" "}</td>
                          <td class="tabla-td ${
                            this.checkboxLatitudLongitud
                              ? " comment-column-data"
                              : ""
                          }"> </td>
                      </tr>
                      `
                        )
                        .join("")}
                    `;
      }

      if (pedidosPreparadosAlmacen.length > 0) {
        contenidoTablaPreparadosAlmacen = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos preparados en almacén</th>`
                          : `<th colspan="12">Pedidos preparados en almacén</th>`
                      }
                    </tr>
                      ${pedidosPreparadosAlmacen
                        .map(
                          (pedido) => `
                      <tr>
                          <td class="tabla-td">${pedido.id}</td>
                          <td class="tabla-td">${this.formatoFecha(
                            pedido.fecha_creacion
                          )}</td>
                          <td class="tabla-td">${pedido.cliente}</td>
                          <td class="tabla-td">${pedido.facturas}</td>
                          <td class="tabla-td">${
                            pedido.contado ? "Sí" : "No"
                          }</td>
                          <td class="tabla-td">${pedido.zona}</td>
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].latitud || ""
                                }</td>`
                          }
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].longitud || ""
                                }</td>`
                          }
                          <td class="tabla-td">${pedido.dia}</td>
                          <td class="tabla-td">${pedido.mensajero}</td>
                          <td class="tabla-td">${" "}</td>
                          <td class="tabla-td ${
                            this.checkboxLatitudLongitud
                              ? " comment-column-data"
                              : ""
                          }"> </td>
                      </tr>
                      `
                        )
                        .join("")}
                    `;
      }

      if (pedidosPreparados.length > 0) {
        contenidoTablaPreparados = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos preparados</th>`
                          : `<th colspan="12">Pedidos preparados</th>`
                      }
                    </tr>
                      ${pedidosPreparados
                        .map(
                          (pedido) => `
                      <tr>
                          <td class="tabla-td">${pedido.id}</td>
                          <td class="tabla-td">${this.formatoFecha(
                            pedido.fecha_creacion
                          )}</td>
                          <td class="tabla-td">${pedido.cliente}</td>
                          <td class="tabla-td">${pedido.facturas}</td>
                          <td class="tabla-td">${
                            pedido.contado ? "Sí" : "No"
                          }</td>
                          <td class="tabla-td">${pedido.zona}</td>
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].latitud || ""
                                }</td>`
                          }
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].longitud || ""
                                }</td>`
                          }
                          <td class="tabla-td">${pedido.dia}</td>
                          <td class="tabla-td">${pedido.mensajero}</td>
                          <td class="tabla-td">${" "}</td>
                          <td class="tabla-td ${
                            this.checkboxLatitudLongitud
                              ? " comment-column-data"
                              : ""
                          }"> </td>
                      </tr>
                      `
                        )
                        .join("")}
                    `;
      }

      if (pedidosEnRuta.length > 0) {
        contenidoTablaEnRuta = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos en ruta de entrega</th>`
                          : `<th colspan="12">Pedidos en ruta de entrega</th>`
                      }
                    </tr>
                      ${pedidosEnRuta
                        .map(
                          (pedido) => `
                      <tr>
                          <td class="tabla-td">${pedido.id}</td>
                          <td class="tabla-td">${this.formatoFecha(
                            pedido.fecha_creacion
                          )}</td>
                          <td class="tabla-td">${pedido.cliente}</td>
                          <td class="tabla-td">${pedido.facturas}</td>
                          <td class="tabla-td">${
                            pedido.contado ? "Sí" : "No"
                          }</td>
                          <td class="tabla-td">${pedido.zona}</td>
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].latitud || ""
                                }</td>`
                          }
                          ${
                            this.checkboxLatitudLongitud
                              ? ""
                              : `<td class="tabla-td">${
                                  arrayLocalizaciones.filter(
                                    (item) => item.id === pedido.id
                                  )[0].longitud || ""
                                }</td>`
                          }
                          <td class="tabla-td">${pedido.dia}</td>
                          <td class="tabla-td">${pedido.mensajero}</td>
                          <td class="tabla-td">${" "}</td>
                          <td class="tabla-td ${
                            this.checkboxLatitudLongitud
                              ? " comment-column-data"
                              : ""
                          }"> </td>
                      </tr>
                      `
                        )
                        .join("")}
                    `;
      }
      const contenidoHTML2 = `
                  </tbody>
              </table>
          </div>
      </body>

      </html>`;

      // Escribir el contenido HTML en la ventana emergente
      ventana.document.open();
      ventana.document.write(contenidoHTML);
      ventana.document.write(contenidoTablaCreados);
      ventana.document.write(contenidoTablaRevisionCredito);
      ventana.document.write(contenidoTablaConfirmacion);
      ventana.document.write(contenidoTablaFacturacion);
      ventana.document.write(contenidoTablaPreparadosAlmacen);
      ventana.document.write(contenidoTablaPreparados);
      ventana.document.write(contenidoTablaEnRuta);
      ventana.document.write(contenidoHTML2);
      ventana.document.close();

      // Imprimir la ventana emergente
      ventana.print();
    },

    async abrirVistaImpresionTablaR() {
      if (this.mensajeroSelected === null) {
        alert("Debe seleccionar un mensajero para poder imprimir.");
        return;
      }

      let arrayFiltrado = [];
      this.dialogImpresion = false;
      this.dialogOpcionesTablaR = false;

      // if (this.itemsFiltrados.length === 0) {
      //   arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;
      // } else {
      //   arrayFiltrado = this.itemsFiltrados;
      // }

      arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;

      console.log(arrayFiltrado);

      // Se obtienen las localizaciones de todos los pedidos
      const localizacionesCache = new Map(); // Map para almacenar localizaciones ya obtenidas

      // Funcion para solicitar la localizacion o obtenerla de la cache
      const getCachedLocalizacion = async (idCliente) => {
        if (!localizacionesCache.has(idCliente)) {
          // Si la localización no ha sido obtenida aún, hacer la solicitud
          const localizacionPromise = this.getLocalizacion(idCliente);
          localizacionesCache.set(idCliente, localizacionPromise); // Guardar la promesa temporalmente
          const localizacion = await localizacionPromise;
          // Actualizar el Map con la localización resuelta
          localizacionesCache.set(idCliente, {
            latitud: localizacion.latitud || "",
            longitud: localizacion.longitud || "",
            dia: localizacion.dia,
          });
        }
        // Usar la localización almacenada en el cache
        return localizacionesCache.get(idCliente);
      };

      const arrayLocalizaciones = await Promise.all(
        arrayFiltrado.map(async (item) => {
          const localizacion = await getCachedLocalizacion(item.idCliente);
          const nuevoItem = {
            id: item.id,
            latitud: localizacion.latitud,
            longitud: localizacion.longitud,
            dia: localizacion.dia,
            cliente: item.cliente,
            total: item.total,
            facturas: item.facturas,
            contado: item.contado,
            zona: item.zona,
            fecha_creacion: item.fecha_creacion,
            mensajero: item.mensajero,
            estado: item.estado,
          };
          return nuevoItem;
        })
      );
      // console.log(arrayLocalizaciones);

      let nuevoArray = [];

      // Filtrar los pedidos por dia actual si es que se requiere
      if (this.checkboxDia) {
        nuevoArray = arrayLocalizaciones.filter(
          (item) => item.dia === this.currentDay
        );
      } else {
        nuevoArray = arrayLocalizaciones;
      }

      // Filtrar los pedidos seleccionados si es que se requiere
      if (this.checkboxPedidosSeleccionados) {
        const selectedIds = this.selectedPedidos.map((pedido) => pedido.id);
        nuevoArray = arrayLocalizaciones.filter((item) =>
          selectedIds.includes(item.id)
        );

        if (nuevoArray.length === 0) {
          console.log("No hay pedidos seleccionados");
          return;
        }

        this.estadosSelected = [];
        nuevoArray.forEach((item) => {
          if (!this.estadosSelected.includes(item.estado)) {
            this.estadosSelected.push(item.estado);
          }
        });
        this.pedidosCreados = nuevoArray.filter(
          (item) => item.estado === "Pedido creado"
        );
        this.pedidosRevisionCredito = nuevoArray.filter(
          (item) => item.estado === "Revisión de crédito"
        );
        this.pedidosConfirmacion = nuevoArray.filter(
          (item) => item.estado === "Confirmación"
        );
        this.pedidosFacturacion = nuevoArray.filter(
          (item) => item.estado === "Facturación"
        );
        this.pedidosPreparadosAlmacen = nuevoArray.filter(
          (item) => item.estado === "Preparación en almacen"
        );
        this.pedidosPreparados = nuevoArray.filter(
          (item) => item.estado === "Preparado"
        );
        this.pedidosEnRuta = nuevoArray.filter(
          (item) => item.estado === "En ruta de entrega"
        );
      } else {
        nuevoArray = arrayLocalizaciones;
      }

      // Separamos los pedidos por estado para mostrarlos en la tabla
      let pedidosCreados = [];
      let pedidosRevisionCredito = [];
      let pedidosConfirmacion = [];
      let pedidosFacturacion = [];
      let pedidosPreparadosAlmacen = [];
      let pedidosPreparados = [];
      let pedidosEnRuta = [];

      if (this.estadosSelected.includes("Pedido creado")) {
        pedidosCreados = nuevoArray.filter(
          (item) => item.estado === "Pedido creado"
        );
      }

      if (this.estadosSelected.includes("Revisión de crédito")) {
        pedidosRevisionCredito = nuevoArray.filter(
          (item) => item.estado === "Revisión de crédito"
        );
      }

      if (this.estadosSelected.includes("Confirmación")) {
        pedidosConfirmacion = nuevoArray.filter(
          (item) => item.estado === "Confirmación"
        );
      }

      if (this.estadosSelected.includes("Facturación")) {
        pedidosFacturacion = nuevoArray.filter(
          (item) => item.estado === "Facturación"
        );
      }

      if (this.estadosSelected.includes("Preparación en almacén")) {
        pedidosPreparadosAlmacen = nuevoArray.filter(
          (item) => item.estado === "Preparación en almacén"
        );
      }

      if (this.estadosSelected.includes("Preparado")) {
        pedidosPreparados = nuevoArray.filter(
          (item) => item.estado === "Preparado"
        );
      }

      if (this.estadosSelected.includes("En ruta de entrega")) {
        pedidosEnRuta = nuevoArray.filter(
          (item) => item.estado === "En ruta de entrega"
        );
      }

      //NUEVA IMPLEMENTEACION
      // Obtener el total de cada estado
      const totalPedidos =
        pedidosCreados.length +
        pedidosRevisionCredito.length +
        pedidosConfirmacion.length +
        pedidosFacturacion.length +
        pedidosPreparadosAlmacen.length +
        pedidosPreparados.length +
        pedidosEnRuta.length;

      this.totalSumaCreados = 0;
      this.totalSumaRevisionCredito = 0;
      this.totalSumaConfirmacion = 0;
      this.totalSumaFacturacion = 0;
      this.totalSumaPreparadosAlmacen = 0;
      this.totalSumaPreparados = 0;
      this.totalSumaEnRuta = 0;

      //FIN DE NUEVA IMPLEMENTACION

      // Crear una ventana emergente
      const ventana = window.open("", "_blank");

      // Construir el contenido HTML de la vista previa de impresión
      let contenidoTablaCreados = "";
      let contenidoTablaRevisionCredito = "";
      let contenidoTablaConfirmacion = "";
      let contenidoTablaFacturacion = "";
      let contenidoTablaPreparadosAlmacen = "";
      let contenidoTablaPreparados = "";
      let contenidoTablaEnRuta = "";
      const contenidoHTML = `
      <!doctype html>
      <html lang="es">

      <head>

          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Pedidos</title>
          <!-- Enlace al archivo CSS de Bootstrap -->
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"
              integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
          <style>
              @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap');
              @page { size: portrait; } 

              body {
                  font-family: Arial, sans-serif;
                  display: flex;
                  flex-direction: column;
                  height: 100vh;
                  margin: 0;
              }

              .content {
                  flex: 1;
              }

              .table-table {
                  width: 100%;
                  border-collapse: collapse;
                  font-size: 8px;
                  border-spacing: 0;
              }

              .tabla-th {
                  background-color: #f2f2f2;
                  padding: 0px;
                  text-align: left;
              }

              .tabla-td {
                  padding: 0px;
                  text-align: left;
              }

              .datos-table {
                  width: 100%;
                  border-collapse: collapse;
              }

              .datos-th {
                  padding: 0px;
                  font-size: 10px;
                  text-align: left;
              }

              .datos-td {
                  padding: 0px;
                  font-size: 14px;
                  text-align: left;
              }

              .comment-column {
                  width: 200px;
              }

              .comment-column-data {
                  width: 200px;
                  height: 50px;
              }

              @page {
                  size: A4 portrait;
                  margin-top: 20mm;
                  margin-bottom: 10mm;
                  margin-left: 10mm;
                  margin-right: 10mm;
              }

              @media print {
                    body {
                      margin: 0;
                  }
                  
                  /*mantener el estilo de table-secondary*/
                  .table-secondary {
                      background-color: #f2f2f2 !important;
                      -webkit-print-color-adjust: exact !important;
                      print-color-adjust: exact !important;
                  }

                  .tabla-th,
                  .tabla-th {
                      background-color: #f2f2f2 !important;
                  }
                  th, td {
                    border: 1px solid black;
                    padding: 10px;
                    text-align: left;
                  }
                    img .logo {
                        display: block;
                    }
                      .header {
                        background-color: #28B2BB !important;
                        color: #fff !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                      }
                        img{
                          -webkit-print-color-adjust: exact !important;
                          print-color-adjust: exact !important;
                        }
                  .full-width-row td {
                    background-color: #f2f2f2 !important;
                    -webkit-print-color-adjust: exact !important;
                    print-color-adjust: exact !important;
                  }        
              }

.header {
  padding-top: 8px;
  padding-bottom: 0px;
  background: #28B2BB;
  border-bottom: 3px solid #ccc;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  border: 1px solid black;
}

.logo {
position: absolute;
  width: 100px;
  height: 80px;
  margin-left: 8px;
  margin-top: 8px;
  /* posicionar a la izquierda */
  left: 0;
  /* posicionar arriba */
  top: 0;
  filter: drop-shadow(0 0 3px white);
}

.fecha-creacion {
position: absolute;
  text-align: right;
  margin-right: 10px;
  margin-top: 35px;
  right: 0;
  top: 0;
  font-size: 10px;
}

.full-width-row td {
            padding: 15px;
            background-color: #f9f9f9;
            text-align: center;
            border-top: 1px solid #ddd;
            /* Establece el color del texto y el fondo para el contenido adicional */
            font-weight: bold;
            font-size: 8px;
        }

         .firma-line {
            border-top: 1px solid black;
            margin-top: 60px;
            width: 100%;
            text-align: center;
        }

        .firma-cell {
            padding-top: 80px;
            text-align: center;
        }
            .total {
            text-align: right;
            font-weight: bold;
        }
          </style>
      </head>

      <body class="d-flex">

          <div class="d-flex flex-column">
              <div class="header">
                <img class="logo" src="${require("@/assets/log.png")}" alt="Logo de la empresa">
                GRUPO PROSTASA DE MEXICO S.A. DE C.V.<br>
               <br> Calle Lago de Opacarai No. 3143-B Colonia Lomas Del Boulevard <br>
              Culiacan Sinoloa, C.P 80120 <br>
              Tel: 667 275487 y 667 2840927
              <p class="fecha-creacion">Fecha de impresión: <br> ${new Date().toLocaleString()}</p></div>
              <table class="table-table table table-bordered table-sm">
                  <thead>
                      <tr>
                          <th class="tabla-th">ID</th>
                          <th class="tabla-th">Fecha pedido</th> 
                          <th class="tabla-th">Facturas</th> 
                          <th class="tabla-th">Cliente</th>
                          <th class="tabla-th">Importe</th>
                          <th class="tabla-th">Crédito /<br> Contado</th>
                          ${
                            this.checkboxZona
                              ? "<th class='tabla-th'>Zona</th>"
                              : ""
                          }
                         <th class="tabla-th">Dia</th>
                         <th class="tabla-th ${
                           this.checkboxLatitudLongitud ? " comment-column" : ""
                         }">Observaciones</th>
                      </tr>
                  </thead>
                  <tbody>`;

      if (pedidosCreados.length > 0) {
        this.totalSumaCreados = 0;
        contenidoTablaCreados = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos creados</th>`
                          : `<th colspan="12">Pedidos creados</th>`
                      }
                    </tr>
                     ${pedidosCreados
                       .map((pedido) => {
                         // Sumar cada total al totalSuma
                         this.totalSumaCreados += parseFloat(pedido.total);

                         return `
                <tr>
                    <td class="tabla-td">${pedido.id}</td>
                  <td class="tabla-td">${this.formatoFecha(
                    pedido.fecha_creacion
                  )}</td> </tr>
                  <td class="tabla-td">${pedido.facturas}</td>
                   <td class="tabla-td">${pedido.cliente}</td>
                   <th class="tabla-th">$${pedido.total
                     .toFixed(2)
                     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                  <td class="tabla-td">${
                    pedido.contado ? "Contado" : "Credito"
                  }</td>
                    ${
                      this.checkboxZona
                        ? `<th class='tabla-th'>${pedido.zona}</th>`
                        : ""
                    }
                   <td class="tabla-td">${pedido.dia}</td>
                   <td class="tabla-td">${" "}</td>
                </tr>
            `;
                       })
                       .join("")}
        <tr>
            <td colspan="4" class="total">Total:</td>
            <td class="total">$${this.totalSumaCreados
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td colspan="5"></td>
        </tr>
        <tr class="full-width-row">
   
    <td colspan="12" class="total">SON: ${(() => {
      const numeroATexto = (numero) => {
        const unidades = [
          "",
          "UNO",
          "DOS",
          "TRES",
          "CUATRO",
          "CINCO",
          "SEIS",
          "SIETE",
          "OCHO",
          "NUEVE",
        ];
        const decenas = [
          "",
          "",
          "VEINTE",
          "TREINTA",
          "CUARENTA",
          "CINCUENTA",
          "SESENTA",
          "SETENTA",
          "OCHENTA",
          "NOVENTA",
        ];
        const especiales = [
          "DIEZ",
          "ONCE",
          "DOCE",
          "TRECE",
          "CATORCE",
          "QUINCE",
          "DIECISEIS",
          "DIECISIETE",
          "DIECIOCHO",
          "DIECINUEVE",
        ];
        const centenas = [
          "",
          "CIENTO",
          "DOSCIENTOS",
          "TRESCIENTOS",
          "CUATROCIENTOS",
          "QUINIENTOS",
          "SEISCIENTOS",
          "SETECIENTOS",
          "OCHOCIENTOS",
          "NOVECIENTOS",
        ];

        if (numero === 0) return "CERO";
        if (numero === 100) return "CIEN";

        let texto = "";

        // Manejar miles
        if (numero >= 1000) {
          const miles = Math.floor(numero / 1000);
          if (miles === 1) {
            texto += "MIL ";
          } else {
            texto += numeroATexto(miles) + " MIL ";
          }
          numero = numero % 1000;
        }

        // Manejar centenas
        if (numero > 99) {
          texto += centenas[Math.floor(numero / 100)] + " ";
          numero = numero % 100;
        }

        // Manejar decenas y unidades
        if (numero > 19) {
          texto +=
            decenas[Math.floor(numero / 10)] +
            (numero % 10 === 0 ? "" : " Y " + unidades[numero % 10]);
        } else if (numero > 9) {
          texto += especiales[numero - 10];
        } else {
          texto += unidades[numero];
        }

        return texto.trim();
      };

      const total = this.totalSumaCreados.toFixed(2).split(".");
      const parteEntera = parseInt(total[0]);
      const parteDecimal = parseInt(total[1]);

      return `${numeroATexto(parteEntera)} PESOS ${
        parteDecimal > 0 ? `CON ${numeroATexto(parteDecimal)} CENTAVOS` : ""
      }`;
    })()}</td>
    
</tr>
    `;
      }

      if (pedidosRevisionCredito.length > 0) {
        this.totalSumaRevisionCredito = 0;
        contenidoTablaRevisionCredito = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos en revisión de crédito</th>`
                          : `<th colspan="12">Pedidos en revisión de crédito</th>`
                      }
                    </tr>
                      ${pedidosRevisionCredito
                        .map((pedido) => {
                          // Sumar cada total al totalSuma
                          this.totalSumaRevisionCredito += parseFloat(
                            pedido.total
                          );

                          return `
                <tr>
                    <td class="tabla-td">${pedido.id}</td>
                    <td class="tabla-td">${this.formatoFecha(
                      pedido.fecha_creacion
                    )}</td>
                    <td class="tabla-td">${pedido.facturas}</td>
                    <td class="tabla-td">${pedido.cliente}</td>
                    <th class="tabla-th">$${pedido.total
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                    <td class="tabla-td">${
                      pedido.contado ? "Contado" : "Credito"
                    }</td>
                    ${
                      this.checkboxZona
                        ? `<th class='tabla-th'>${pedido.zona}</th>`
                        : ""
                    }
                    <td class="tabla-td">${pedido.dia}</td>
                    <td class="tabla-td">${" "}</td>
                </tr>
            `;
                        })
                        .join("")}
        <tr>
            <td colspan="4" class="total">Total:</td>
            <td class="total">$${this.totalSumaRevisionCredito
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td colspan="5"></td>
        </tr>
        <tr class="full-width-row">
   
    <td colspan="12" class="total">SON: ${(() => {
      const numeroATexto = (numero) => {
        const unidades = [
          "",
          "UNO",
          "DOS",
          "TRES",
          "CUATRO",
          "CINCO",
          "SEIS",
          "SIETE",
          "OCHO",
          "NUEVE",
        ];
        const decenas = [
          "",
          "",
          "VEINTE",
          "TREINTA",
          "CUARENTA",
          "CINCUENTA",
          "SESENTA",
          "SETENTA",
          "OCHENTA",
          "NOVENTA",
        ];
        const especiales = [
          "DIEZ",
          "ONCE",
          "DOCE",
          "TRECE",
          "CATORCE",
          "QUINCE",
          "DIECISEIS",
          "DIECISIETE",
          "DIECIOCHO",
          "DIECINUEVE",
        ];
        const centenas = [
          "",
          "CIENTO",
          "DOSCIENTOS",
          "TRESCIENTOS",
          "CUATROCIENTOS",
          "QUINIENTOS",
          "SEISCIENTOS",
          "SETECIENTOS",
          "OCHOCIENTOS",
          "NOVECIENTOS",
        ];

        if (numero === 0) return "CERO";
        if (numero === 100) return "CIEN";

        let texto = "";

        // Manejar miles
        if (numero >= 1000) {
          const miles = Math.floor(numero / 1000);
          if (miles === 1) {
            texto += "MIL ";
          } else {
            texto += numeroATexto(miles) + " MIL ";
          }
          numero = numero % 1000;
        }

        // Manejar centenas
        if (numero > 99) {
          texto += centenas[Math.floor(numero / 100)] + " ";
          numero = numero % 100;
        }

        // Manejar decenas y unidades
        if (numero > 19) {
          texto +=
            decenas[Math.floor(numero / 10)] +
            (numero % 10 === 0 ? "" : " Y " + unidades[numero % 10]);
        } else if (numero > 9) {
          texto += especiales[numero - 10];
        } else {
          texto += unidades[numero];
        }

        return texto.trim();
      };

      const total = this.totalSumaRevisionCredito.toFixed(2).split(".");
      const parteEntera = parseInt(total[0]);
      const parteDecimal = parseInt(total[1]);

      return `${numeroATexto(parteEntera)} PESOS ${
        parteDecimal > 0 ? `CON ${numeroATexto(parteDecimal)} CENTAVOS` : ""
      }`;
    })()}</td>
    
</tr>
    `;
      }

      if (pedidosConfirmacion.length > 0) {
        this.totalSumaConfirmacion = 0;
        contenidoTablaConfirmacion = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos en confirmación</th>`
                          : `<th colspan="12">Pedidos en confirmación</th>`
                      }
                    </tr>
                      ${pedidosConfirmacion
                        .map((pedido) => {
                          // Sumar cada total al totalSuma
                          this.totalSumaConfirmacion += parseFloat(
                            pedido.total
                          );

                          return `
                <tr>
                    <td class="tabla-td">${pedido.id}</td>
                    <td class="tabla-td">${this.formatoFecha(
                      pedido.fecha_creacion
                    )}</td>
                    <td class="tabla-td">${pedido.facturas}</td>
                    <td class="tabla-td">${pedido.cliente}</td>
                    <th class="tabla-th">$${pedido.total
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                    <td class="tabla-td">${
                      pedido.contado ? "Contado" : "Credito"
                    }</td>
                    ${
                      this.checkboxZona
                        ? `<th class='tabla-th'>${pedido.zona}</th>`
                        : ""
                    }
                    <td class="tabla-td">${pedido.dia}</td>
                    <td class="tabla-td">${" "}</td>
                </tr>
            `;
                        })
                        .join("")}
        <tr>
            <td colspan="4" class="total">Total:</td>
            <td class="total">$${this.totalSumaConfirmacion
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td colspan="5"></td>
        </tr>
        <tr class="full-width-row">
   
    <td colspan="12" class="total">SON: ${(() => {
      const numeroATexto = (numero) => {
        const unidades = [
          "",
          "UNO",
          "DOS",
          "TRES",
          "CUATRO",
          "CINCO",
          "SEIS",
          "SIETE",
          "OCHO",
          "NUEVE",
        ];
        const decenas = [
          "",
          "",
          "VEINTE",
          "TREINTA",
          "CUARENTA",
          "CINCUENTA",
          "SESENTA",
          "SETENTA",
          "OCHENTA",
          "NOVENTA",
        ];
        const especiales = [
          "DIEZ",
          "ONCE",
          "DOCE",
          "TRECE",
          "CATORCE",
          "QUINCE",
          "DIECISEIS",
          "DIECISIETE",
          "DIECIOCHO",
          "DIECINUEVE",
        ];
        const centenas = [
          "",
          "CIENTO",
          "DOSCIENTOS",
          "TRESCIENTOS",
          "CUATROCIENTOS",
          "QUINIENTOS",
          "SEISCIENTOS",
          "SETECIENTOS",
          "OCHOCIENTOS",
          "NOVECIENTOS",
        ];

        if (numero === 0) return "CERO";
        if (numero === 100) return "CIEN";

        let texto = "";

        // Manejar miles
        if (numero >= 1000) {
          const miles = Math.floor(numero / 1000);
          if (miles === 1) {
            texto += "MIL ";
          } else {
            texto += numeroATexto(miles) + " MIL ";
          }
          numero = numero % 1000;
        }

        // Manejar centenas
        if (numero > 99) {
          texto += centenas[Math.floor(numero / 100)] + " ";
          numero = numero % 100;
        }

        // Manejar decenas y unidades
        if (numero > 19) {
          texto +=
            decenas[Math.floor(numero / 10)] +
            (numero % 10 === 0 ? "" : " Y " + unidades[numero % 10]);
        } else if (numero > 9) {
          texto += especiales[numero - 10];
        } else {
          texto += unidades[numero];
        }

        return texto.trim();
      };

      const total = this.totalSumaConfirmacion.toFixed(2).split(".");
      const parteEntera = parseInt(total[0]);
      const parteDecimal = parseInt(total[1]);

      return `${numeroATexto(parteEntera)} PESOS ${
        parteDecimal > 0 ? `CON ${numeroATexto(parteDecimal)} CENTAVOS` : ""
      }`;
    })()}</td>
    
</tr>
    `;
      }

      if (pedidosFacturacion.length > 0) {
        this.totalSumaFacturacion = 0;
        contenidoTablaFacturacion = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos en facturación</th>`
                          : `<th colspan="12">Pedidos en facturación</th>`
                      }
                    </tr>
                      ${pedidosFacturacion
                        .map((pedido) => {
                          // Sumar cada total al totalSuma
                          this.totalSumaFacturacion += parseFloat(pedido.total);

                          return `
                <tr>
                    <td class="tabla-td">${pedido.id}</td>
                    <td class="tabla-td">${this.formatoFecha(
                      pedido.fecha_creacion
                    )}</td>
                    <td class="tabla-td">${pedido.facturas}</td>
                    <td class="tabla-td">${pedido.cliente}</td>
                    <th class="tabla-th">$${pedido.total
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                    <td class="tabla-td">${
                      pedido.contado ? "Contado" : "Credito"
                    }</td>
                    ${
                      this.checkboxZona
                        ? `<th class='tabla-th'>${pedido.zona}</th>`
                        : ""
                    }
                    <td class="tabla-td">${pedido.dia}</td>
                    <td class="tabla-td">${" "}</td>
                </tr>
            `;
                        })
                        .join("")}
        <tr>
            <td colspan="4" class="total">Total:</td>
            <td class="total">$${this.totalSumaFacturacion
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td colspan="5"></td>
        </tr>
        <tr class="full-width-row">
   
    <td colspan="12" class="total">SON: ${(() => {
      const numeroATexto = (numero) => {
        const unidades = [
          "",
          "UNO",
          "DOS",
          "TRES",
          "CUATRO",
          "CINCO",
          "SEIS",
          "SIETE",
          "OCHO",
          "NUEVE",
        ];
        const decenas = [
          "",
          "",
          "VEINTE",
          "TREINTA",
          "CUARENTA",
          "CINCUENTA",
          "SESENTA",
          "SETENTA",
          "OCHENTA",
          "NOVENTA",
        ];
        const especiales = [
          "DIEZ",
          "ONCE",
          "DOCE",
          "TRECE",
          "CATORCE",
          "QUINCE",
          "DIECISEIS",
          "DIECISIETE",
          "DIECIOCHO",
          "DIECINUEVE",
        ];
        const centenas = [
          "",
          "CIENTO",
          "DOSCIENTOS",
          "TRESCIENTOS",
          "CUATROCIENTOS",
          "QUINIENTOS",
          "SEISCIENTOS",
          "SETECIENTOS",
          "OCHOCIENTOS",
          "NOVECIENTOS",
        ];

        if (numero === 0) return "CERO";
        if (numero === 100) return "CIEN";

        let texto = "";

        // Manejar miles
        if (numero >= 1000) {
          const miles = Math.floor(numero / 1000);
          if (miles === 1) {
            texto += "MIL ";
          } else {
            texto += numeroATexto(miles) + " MIL ";
          }
          numero = numero % 1000;
        }

        // Manejar centenas
        if (numero > 99) {
          texto += centenas[Math.floor(numero / 100)] + " ";
          numero = numero % 100;
        }

        // Manejar decenas y unidades
        if (numero > 19) {
          texto +=
            decenas[Math.floor(numero / 10)] +
            (numero % 10 === 0 ? "" : " Y " + unidades[numero % 10]);
        } else if (numero > 9) {
          texto += especiales[numero - 10];
        } else {
          texto += unidades[numero];
        }

        return texto.trim();
      };

      const total = this.totalSumaFacturacion.toFixed(2).split(".");
      const parteEntera = parseInt(total[0]);
      const parteDecimal = parseInt(total[1]);

      return `${numeroATexto(parteEntera)} PESOS ${
        parteDecimal > 0 ? `CON ${numeroATexto(parteDecimal)} CENTAVOS` : ""
      }`;
    })()}</td>
    
</tr>
    `;
      }

      if (pedidosPreparadosAlmacen.length > 0) {
        this.totalSumaPreparadosAlmacen = 0;
        contenidoTablaPreparadosAlmacen = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos preparados en almacén</th>`
                          : `<th colspan="12">Pedidos preparados en almacén</th>`
                      }
                    </tr>
                      ${pedidosPreparadosAlmacen
                        .map((pedido) => {
                          // Sumar cada total al totalSuma
                          this.totalSumaPreparadosAlmacen += parseFloat(
                            pedido.total
                          );

                          return `
                <tr>
                    <td class="tabla-td">${pedido.id}</td>
                    <td class="tabla-td">${this.formatoFecha(
                      pedido.fecha_creacion
                    )}</td>
                    <td class="tabla-td">${pedido.facturas}</td>
                    <td class="tabla-td">${pedido.cliente}</td>
                    <th class="tabla-th">$${pedido.total
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                    <td class="tabla-td">${
                      pedido.contado ? "Contado" : "Credito"
                    }</td>
                    ${
                      this.checkboxZona
                        ? `<th class='tabla-th'>${pedido.zona}</th>`
                        : ""
                    }
                    <td class="tabla-td">${pedido.dia}</td>
                    <td class="tabla-td">${" "}</td>
                </tr>
            `;
                        })
                        .join("")}
        <tr>
            <td colspan="4" class="total">Total:</td>
            <td class="total">$${this.totalSumaPreparadosAlmacen
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td colspan="5"></td>
        </tr>
        <tr class="full-width-row">
   
    <td colspan="12" class="total">SON: ${(() => {
      const numeroATexto = (numero) => {
        const unidades = [
          "",
          "UNO",
          "DOS",
          "TRES",
          "CUATRO",
          "CINCO",
          "SEIS",
          "SIETE",
          "OCHO",
          "NUEVE",
        ];
        const decenas = [
          "",
          "",
          "VEINTE",
          "TREINTA",
          "CUARENTA",
          "CINCUENTA",
          "SESENTA",
          "SETENTA",
          "OCHENTA",
          "NOVENTA",
        ];
        const especiales = [
          "DIEZ",
          "ONCE",
          "DOCE",
          "TRECE",
          "CATORCE",
          "QUINCE",
          "DIECISEIS",
          "DIECISIETE",
          "DIECIOCHO",
          "DIECINUEVE",
        ];
        const centenas = [
          "",
          "CIENTO",
          "DOSCIENTOS",
          "TRESCIENTOS",
          "CUATROCIENTOS",
          "QUINIENTOS",
          "SEISCIENTOS",
          "SETECIENTOS",
          "OCHOCIENTOS",
          "NOVECIENTOS",
        ];

        if (numero === 0) return "CERO";
        if (numero === 100) return "CIEN";

        let texto = "";

        // Manejar miles
        if (numero >= 1000) {
          const miles = Math.floor(numero / 1000);
          if (miles === 1) {
            texto += "MIL ";
          } else {
            texto += numeroATexto(miles) + " MIL ";
          }
          numero = numero % 1000;
        }

        // Manejar centenas
        if (numero > 99) {
          texto += centenas[Math.floor(numero / 100)] + " ";
          numero = numero % 100;
        }

        // Manejar decenas y unidades
        if (numero > 19) {
          texto +=
            decenas[Math.floor(numero / 10)] +
            (numero % 10 === 0 ? "" : " Y " + unidades[numero % 10]);
        } else if (numero > 9) {
          texto += especiales[numero - 10];
        } else {
          texto += unidades[numero];
        }

        return texto.trim();
      };

      const total = this.totalSumaPreparadosAlmacen.toFixed(2).split(".");
      const parteEntera = parseInt(total[0]);
      const parteDecimal = parseInt(total[1]);

      return `${numeroATexto(parteEntera)} PESOS ${
        parteDecimal > 0 ? `CON ${numeroATexto(parteDecimal)} CENTAVOS` : ""
      }`;
    })()}</td>
    
</tr>
    `;
      }

      if (pedidosPreparados.length > 0) {
        this.totalSumaPreparados = 0;
        contenidoTablaPreparados = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos preparados</th>`
                          : `<th colspan="12">Pedidos preparados</th>`
                      }
                    </tr>
                      ${pedidosPreparados
                        .map((pedido) => {
                          // Sumar cada total al totalSuma
                          this.totalSumaPreparados += parseFloat(pedido.total);

                          return `
                <tr>
                    <td class="tabla-td">${pedido.id}</td>
                    <td class="tabla-td">${this.formatoFecha(
                      pedido.fecha_creacion
                    )}</td>
                    <td class="tabla-td">${pedido.facturas}</td>
                    <td class="tabla-td">${pedido.cliente}</td>
                    <th class="tabla-th">$${pedido.total
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                    <td class="tabla-td">${
                      pedido.contado ? "Contado" : "Credito"
                    }</td>
                    ${
                      this.checkboxZona
                        ? `<th class='tabla-th'>${pedido.zona}</th>`
                        : ""
                    }
                    <td class="tabla-td">${pedido.dia}</td>
                    <td class="tabla-td">${" "}</td>
                </tr>
            `;
                        })
                        .join("")}
        <tr>
            <td colspan="4" class="total">Total:</td>
            <td class="total">$${this.totalSumaPreparados
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td colspan="5"></td>
        </tr>
        <tr class="full-width-row">
    
    <td colspan="12" class="total">SON: ${(() => {
      const numeroATexto = (numero) => {
        const unidades = [
          "",
          "UNO",
          "DOS",
          "TRES",
          "CUATRO",
          "CINCO",
          "SEIS",
          "SIETE",
          "OCHO",
          "NUEVE",
        ];
        const decenas = [
          "",
          "",
          "VEINTE",
          "TREINTA",
          "CUARENTA",
          "CINCUENTA",
          "SESENTA",
          "SETENTA",
          "OCHENTA",
          "NOVENTA",
        ];
        const especiales = [
          "DIEZ",
          "ONCE",
          "DOCE",
          "TRECE",
          "CATORCE",
          "QUINCE",
          "DIECISEIS",
          "DIECISIETE",
          "DIECIOCHO",
          "DIECINUEVE",
        ];
        const centenas = [
          "",
          "CIENTO",
          "DOSCIENTOS",
          "TRESCIENTOS",
          "CUATROCIENTOS",
          "QUINIENTOS",
          "SEISCIENTOS",
          "SETECIENTOS",
          "OCHOCIENTOS",
          "NOVECIENTOS",
        ];

        if (numero === 0) return "CERO";
        if (numero === 100) return "CIEN";

        let texto = "";

        // Manejar miles
        if (numero >= 1000) {
          const miles = Math.floor(numero / 1000);
          if (miles === 1) {
            texto += "MIL ";
          } else {
            texto += numeroATexto(miles) + " MIL ";
          }
          numero = numero % 1000;
        }

        // Manejar centenas
        if (numero > 99) {
          texto += centenas[Math.floor(numero / 100)] + " ";
          numero = numero % 100;
        }

        // Manejar decenas y unidades
        if (numero > 19) {
          texto +=
            decenas[Math.floor(numero / 10)] +
            (numero % 10 === 0 ? "" : " Y " + unidades[numero % 10]);
        } else if (numero > 9) {
          texto += especiales[numero - 10];
        } else {
          texto += unidades[numero];
        }

        return texto.trim();
      };

      const total = this.totalSumaPreparados.toFixed(2).split(".");
      const parteEntera = parseInt(total[0]);
      const parteDecimal = parseInt(total[1]);

      return `${numeroATexto(parteEntera)} PESOS ${
        parteDecimal > 0 ? `CON ${numeroATexto(parteDecimal)} CENTAVOS` : ""
      }`;
    })()}</td>
    
</tr>
    `;
      }

      if (pedidosEnRuta.length > 0) {
        this.totalSumaEnRuta = 0; //inicializa la suma total
        contenidoTablaEnRuta = `
                    <tr class="table-secondary">
                      ${
                        this.checkboxLatitudLongitud
                          ? `<th colspan="10">Pedidos en ruta de entrega</th>`
                          : `<th colspan="12">Pedidos en ruta de entrega</th>`
                      }
                    </tr>
                       ${pedidosEnRuta
                         .map((pedido) => {
                           // Sumar cada total al totalSuma
                           this.totalSumaEnRuta += parseFloat(pedido.total);

                           return `
                <tr>
                    <td class="tabla-td">${pedido.id}</td>
                    <td class="tabla-td">${this.formatoFecha(
                      pedido.fecha_creacion
                    )}</td>
                    <td class="tabla-td">${pedido.facturas}</td>
                    <td class="tabla-td">${pedido.cliente}</td>
                    <th class="tabla-th">$${pedido.total
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                    <td class="tabla-td">${
                      pedido.contado ? "Contado" : "Credito"
                    }</td>
                    ${
                      this.checkboxZona
                        ? `<th class='tabla-th'>${pedido.zona}</th>`
                        : ""
                    }
                    <td class="tabla-td">${pedido.dia}</td>
                    <td class="tabla-td">${" "}</td>
                </tr>
            `;
                         })
                         .join("")}
        <tr>
            <td colspan="4" class="total">Total:</td>
            <td class="total">$${this.totalSumaEnRuta
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td colspan="5"></td>
        </tr>

        <tr class="full-width-row">
   
    <td colspan="12" class="total">SON: ${(() => {
      const numeroATexto = (numero) => {
        const unidades = [
          "",
          "UNO",
          "DOS",
          "TRES",
          "CUATRO",
          "CINCO",
          "SEIS",
          "SIETE",
          "OCHO",
          "NUEVE",
        ];
        const decenas = [
          "",
          "",
          "VEINTE",
          "TREINTA",
          "CUARENTA",
          "CINCUENTA",
          "SESENTA",
          "SETENTA",
          "OCHENTA",
          "NOVENTA",
        ];
        const especiales = [
          "DIEZ",
          "ONCE",
          "DOCE",
          "TRECE",
          "CATORCE",
          "QUINCE",
          "DIECISEIS",
          "DIECISIETE",
          "DIECIOCHO",
          "DIECINUEVE",
        ];
        const centenas = [
          "",
          "CIENTO",
          "DOSCIENTOS",
          "TRESCIENTOS",
          "CUATROCIENTOS",
          "QUINIENTOS",
          "SEISCIENTOS",
          "SETECIENTOS",
          "OCHOCIENTOS",
          "NOVECIENTOS",
        ];

        if (numero === 0) return "CERO";
        if (numero === 100) return "CIEN";

        let texto = "";

        // Manejar miles
        if (numero >= 1000) {
          const miles = Math.floor(numero / 1000);
          if (miles === 1) {
            texto += "MIL ";
          } else {
            texto += numeroATexto(miles) + " MIL ";
          }
          numero = numero % 1000;
        }

        // Manejar centenas
        if (numero > 99) {
          texto += centenas[Math.floor(numero / 100)] + " ";
          numero = numero % 100;
        }

        // Manejar decenas y unidades
        if (numero > 19) {
          texto +=
            decenas[Math.floor(numero / 10)] +
            (numero % 10 === 0 ? "" : " Y " + unidades[numero % 10]);
        } else if (numero > 9) {
          texto += especiales[numero - 10];
        } else {
          texto += unidades[numero];
        }

        return texto.trim();
      };

      const total = this.totalSumaEnRuta.toFixed(2).split(".");
      const parteEntera = parseInt(total[0]);
      const parteDecimal = parseInt(total[1]);

      return `${numeroATexto(parteEntera)} PESOS ${
        parteDecimal > 0 ? `CON ${numeroATexto(parteDecimal)} CENTAVOS` : ""
      }`;
    })()}</td>
    
</tr>
    `;
      }
      const contenidoHTML2 = `
      <tr class="full-width-row">
        <td colspan="12">
          YO: <u>${
            this.mensajeroSelected.toUpperCase()
              ? this.mensajeroSelected.toUpperCase()
              : "Mensajero no asignado".toUpperCase()
          }</u>: DEBO Y ME HAGO RESPONSABLE POR EL RESGUARDO, COBRANZA Y/O EXTRAVIO DE LAS FACTURAS
                  ORIGINALES, AQUI RELACIONADAS Y ME COMPROMETO A ENTREGAR EL PAGO O DEVOLUCION DE LAS MISMA EN TIEMPO Y FORMA, EN CASO DE QUE NO SEA PAGADA
                  POR EL CLIENTE A SU VENCIMIENTO, PAGARE INCONDICIONALMENTE A LA ORDEN DE GRUPO PROSTASA DE MEXICO S.A. DE C.V.
          </td>

      </tr>

            <tr>
            <!-- Celda de firma izquierda -->
            <td class="firma-cell" colspan="3">
                <div class="firma-line"></div>
                <div>${this.currentUser.nombre.toUpperCase()}</div>
                <div>ENTREGA</div>
            </td>

            <!-- Celda vacía en el medio si es necesario -->
            <td colspan="3"></td>

           <!-- Celda de firma derecha -->
            <td class="firma-cell" colspan="3">
                <div class="firma-line"></div>
                <div>${
                  this.mensajeroSelected.toUpperCase()
                    ? this.mensajeroSelected.toUpperCase()
                    : "Mensajero no asignado".toUpperCase()
                }</div>
                <div>ACEPTO</div>
            </td>
        </tr>
                  </tbody>
              </table>
          </div>
          
      </body>

      </html>`;

      // Escribir el contenido HTML en la ventana emergente
      ventana.document.open();
      ventana.document.write(contenidoHTML);
      ventana.document.write(contenidoTablaCreados);
      ventana.document.write(contenidoTablaRevisionCredito);
      ventana.document.write(contenidoTablaConfirmacion);
      ventana.document.write(contenidoTablaFacturacion);
      ventana.document.write(contenidoTablaPreparadosAlmacen);
      ventana.document.write(contenidoTablaPreparados);
      ventana.document.write(contenidoTablaEnRuta);
      ventana.document.write(contenidoHTML2);
      ventana.document.close();

      // Imprimir la ventana emergente
      ventana.print();

      const totalGeneral =
        this.totalSumaCreados +
        this.totalSumaRevisionCredito +
        this.totalSumaConfirmacion +
        this.totalSumaFacturacion +
        this.totalSumaPreparadosAlmacen +
        this.totalSumaPreparados +
        this.totalSumaEnRuta;

      console.log(this.totalSumaCreados);
      console.log(this.totalSumaRevisionCredito);
      console.log(this.totalSumaConfirmacion);
      console.log(this.totalSumaFacturacion);
      console.log(this.totalSumaPreparadosAlmacen);
      console.log(this.totalSumaPreparados);
      console.log(this.totalSumaEnRuta);

      const nuevoFormato = {
        fecha: new Date().toLocaleString(),
        mensajero: this.mensajeroSelected,
        numeroPedidos: totalPedidos,
        total: totalGeneral,
        contenidoHTML: contenidoHTML,
        contenidoHTML2: contenidoHTML2,
        contenidoTablaCreados: contenidoTablaCreados,
        contenidoTablaRevisionCredito: contenidoTablaRevisionCredito,
        contenidoTablaConfirmacion: contenidoTablaConfirmacion,
        contenidoTablaFacturacion: contenidoTablaFacturacion,
        contenidoTablaPreparadosAlmacen: contenidoTablaPreparadosAlmacen,
        contenidoTablaPreparados: contenidoTablaPreparados,
        contenidoTablaEnRuta: contenidoTablaEnRuta,
      };

      this.formatosImpresionGuardados.push(nuevoFormato);
      this.guardarFormatosImpresion();

      window.onload = function () {
        const logoImg = document.querySelector(".logo");
        logoImg.onload = () => {
          ventana.print();
        };
      };

      this.mensajeroSelected = "";
    },

    async abrirVistaImpresionEtiquetas() {
      let arrayFiltrado = [];
      this.dialogImpresion = false;

      if (this.itemsFiltrados.length === 0) {
        arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;
      } else {
        arrayFiltrado = this.itemsFiltrados;
      }
      console.log(arrayFiltrado);
      // Se obtienen los comentarios de todos los pedidos
      const arrayComentarios = await this.getComentarios();

      const fechaActual = new Date();
      // Crear una ventana emergente
      const ventana = window.open("", "_blank");

      // Construir el contenido HTML de la vista previa de impresión
      const contenidoHTML = `
      <html>
        <head>
          <style>
            /* Estilos adicionales para la vista previa de impresión */
            @media print {
              /* Estilos personalizados para la vista previa de impresión */
              body {
                font-family: Arial, sans-serif;
              }
              .pedido {
                position: relative; /* Agregar posición relativa para posicionar el texto */
                margin-bottom: 20px;
                padding: 10px;
                border: 2px solid #ccc;
                /* Evitar que el pedido se corte entre páginas */
                page-break-inside: avoid;
              }
              .texto-prioridad {
                position: absolute;
                top: 15px; /* Ajustar la posición vertical del texto */
                right: 20px; /* Ajustar la posición horizontal del texto */
                font-size: 20px;
                font-weight: bold;
              }
              .texto-pago {
                position: absolute;
                top: 40px; /* Ajustar la posición vertical del texto */
                right: 20px; /* Ajustar la posición horizontal del texto */
                font-size: 20px;
                font-weight: bold;
              }
              .texto-zona {
                position: absolute;
                top: 65px; /* Ajustar la posición vertical del texto */
                right: 20px; /* Ajustar la posición horizontal del texto */
                font-size: 20px;
                font-weight: bold;
              }
              .titulo {
                font-size: 19px;
                font-weight: bold;
              }
              .subtitulo {
                font-weight: bold;
                margin-bottom: 5px;
                font-size: 15px;
              }
              .propiedad {
                margin-bottom: 3px;
              }
              .valor {
                margin-left: 3px;
                font-size: 14px;
              }
              .productos {
                margin-top: 5px;
                margin-bottom: 3px;
              }
              .producto {
                margin-bottom: 3px;
              }
            }
          </style>
        </head>
        <body>
          ${arrayFiltrado
            .map(
              (pedido) => `
              <div class="pedido">
                <div class="titulo">Pedido ID: ${pedido.id}  ${
                pedido.estado
              }</div>
                <div class="propiedad">
                  <span class="subtitulo">Creado por:</span>
                  <span class="valor">${pedido.creado_por}</span>
                </div>
                <div class="texto-prioridad">${
                  pedido.prioridad ? "Prioridad" : ""
                }</div>
                <div class="propiedad">
                  <span class="subtitulo">Fecha de creación:</span>
                  <span class="valor">${this.getFechaLegible(
                    pedido.fecha_creacion
                  )}</span>
                </div>
                <div class="texto-pago">${pedido.pago ? pedido.pago : ""}</div>
                <div class="texto-zona">${
                  pedido.zona ? pedido.zona : "Sin zona"
                }</div>
                <div class="propiedad">
                  <span class="subtitulo">Fecha de impresión:</span>
                  <span class="valor">${this.getFechaLegible(
                    fechaActual
                  )}</span>
                </div>
                <div class="productos">
                  <div class="subtitulo">Productos:</div>
                  ${pedido.productos.lista_productos
                    .map(
                      (producto) => `
                      <div class="producto">
                        <span>${producto.codigo} - ${producto.nombre}</span>
                        <span class="valor">Cantidad: ${producto.cantidad} </span>
                      </div>
                    `
                    )
                    .join("")}
                </div>
                <div class="propiedad">
                  <span class="subtitulo">Cliente:</span>
                  <span class="valor">${pedido.cliente}</span>
                </div>

                <div class="propiedad">
                  <span class="subtitulo">Facturas:</span>
                  <span class="valor">
                    ${pedido.facturas}
                  </span>
                </div>

                <div class="propiedad">
                  <span class="subtitulo">Observaciones:</span>
                  <span class="valor">${pedido.observaciones}</span>
                </div>
                <div class="productos">
                  <span class="subtitulo">Comentarios:</span>
                  ${this.filtrarComentariosPorIdObjeto(
                    pedido.id,
                    arrayComentarios
                  )
                    .map(
                      (comentario) => `
                      <div class="producto">
                        <span>${this.getNombreVendedor(
                          comentario.usuario
                        )} - ${this.getFechaLegible(
                        comentario.fecha_creacion
                      )} - ${comentario.texto_comentario}</span>
                      </div>
                      `
                    )
                    .join("")}
                </div>
                <div class="propiedad">
                  <span class="subtitulo">Total:</span>
                  <span class="valor">$${pedido.total}</span>
                </div>
                <!-- Otras propiedades y detalles del pedido... -->
              </div>
            `
            )
            .join("")}
        </body>
      </html>
    `;

      // Escribir el contenido HTML en la ventana emergente
      ventana.document.open();
      ventana.document.write(contenidoHTML);
      ventana.document.close();

      // Imprimir la ventana emergente
      ventana.print();
    },
    async verificarLocalizaciones() {
      this.pedidosConLocalizacion = [];
      this.pedidosSinLocalizacion = [];
      let arrayFiltrado = [];

      //itemsFitrados contiene los pedidos que se muestran en la tabla
      if (this.itemsFiltrados.length === 0) {
        arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;
      } else {
        arrayFiltrado = this.itemsFiltrados;
      }

      // Se obtienen las localizaciones de todos los pedidos y se les asigna latitud y longitud
      const arrayLocalizaciones = await Promise.all(
        arrayFiltrado.map(async (item) => {
          const localizacion = await this.getLocalizacion(item.idCliente);
          const nuevoItem = {
            id: item.id,
            fecha_creacion: this.formatoFecha(item.fecha_creacion),
            cliente: item.cliente,
            facturas: item.facturas.join("/"), // Unir las facturas en una sola cadena
            zona: item.zona,
            latitud: localizacion.latitud || "",
            longitud: localizacion.longitud || "",
            mensajero: item.mensajero,
            entregado: "",
            comentarios: "",
          };
          return nuevoItem;
        })
      );

      // console.log(arrayLocalizaciones);

      // Se filtran los pedidos con localizacion
      this.pedidosConLocalizacion = arrayLocalizaciones.filter((pedido) => {
        // Verificas si la propiedad 'latitud' existe y no está vacía (si tiene latitud entonces tambien tiene longitud)
        if (
          pedido.latitud !== undefined &&
          pedido.latitud !== null &&
          pedido.latitud !== ""
        ) {
          return true; // Mantienes el objeto en el array original
        } else {
          this.pedidosSinLocalizacion.push(pedido); // Agregas el objeto al array de eliminados
          return false; // Eliminas el objeto del array original
        }
      });

      console.log(this.pedidosConLocalizacion);
      console.log(this.pedidosSinLocalizacion);

      if (
        this.pedidosSinLocalizacion.length > 0 &&
        this.pedidosConLocalizacion.length > 0
      ) {
        this.dialogPedidosSinLocalizacion = true;
      } else if (
        this.pedidosSinLocalizacion.length === 0 &&
        this.pedidosConLocalizacion.length > 0
      ) {
        this.exportToCSV(this.pedidosConLocalizacion);
      } else if (this.pedidosConLocalizacion.length === 0) {
        alert("No hay pedidos con localizacion asignada");
      }
    },
    async exportToCSV(arrayDePedidos) {
      // Generar el contenido CSV
      let csvContent = null;

      // const arrayCsv = await Promise.all(
      //   arrayFiltrado.map(async (item) => {
      //     const localizacion = await this.getLocalizacion(item.idCliente);
      //     const nuevoItem = {
      //       id: item.id,
      //       fecha_creacion: this.formatoFecha(item.fecha_creacion),
      //       cliente: item.cliente,
      //       facturas: item.facturas.join("/"), // Unir las facturas en una sola cadena
      //       zona: item.zona,
      //       // localizacion: localizacion.latitud
      //       //   ? localizacion.latitud + "," + localizacion.longitud
      //       //   : "",
      //       latitud: localizacion.latitud || "",
      //       longitud: localizacion.longitud || "",
      //       mensajero: item.mensajero,
      //       entregado: "",
      //       comentarios: "",
      //     };
      //     return nuevoItem;
      //   })
      // );

      // console.log(arrayCsv);

      csvContent = this.convertArrayOfObjectsToCSV(arrayDePedidos);

      // Crear un objeto Blob
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

      // Crear un enlace de descarga
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = "pedidos.csv";

      // Agregar el enlace al documento y hacer clic en él
      document.body.appendChild(link);
      link.click();

      // Eliminar el enlace del documento
      document.body.removeChild(link);

      this.dialogPedidosSinLocalizacion = false;
      this.dialogImpresion = false;
    },
    convertArrayOfObjectsToCSV(items) {
      const separator = ",";
      const keys = Object.keys(items[0]);

      // Encabezados CSV
      const headerRow = keys.join(separator);

      // Filas CSV
      const rows = items.map((item) => {
        const rowValues = keys.map((key) => {
          let value = item[key];

          if (value === null || value === undefined) {
            value = "";
          } else if (typeof value === "string") {
            // Escapar comillas dobles en el valor
            value = value.replace(/"/g, '""');
            // Encerrar el valor entre comillas si contiene comas, comillas dobles o saltos de línea
            if (
              value.includes(",") ||
              value.includes('"') ||
              value.includes("\n")
            ) {
              value = `"${value}"`;
            }
          }

          return value;
        });

        return rowValues.join(separator);
      });

      return headerRow + "\n" + rows.join("\n");
    },
    formatoPrecio(numero) {
      // Agregar console.log para ver qué valor está entrando
      //console.log("Número recibido:", numero);

      try {
        // Verificar si el número es válido
        if (isNaN(numero)) {
          console.error(
            "El valor proporcionado no es un número válido:",
            numero
          );
          return "$0.00";
        }

        const numeroPrecio = parseFloat(numero);
        const precio = numeroPrecio.toFixed(2); // Limitar a 2 decimales
        const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
        const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

        // Formatear la parte entera con separador de miles
        const parteEnteraFormateada = parteEntera.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
      } catch (error) {
        console.error("Error al formatear el número:", error);
        return "$0.00"; // En caso de error, devolver un valor por defecto
      }
      /*// Formatear el número utilizando una función personalizada
      const numeroPrecio = parseFloat(numero);
      const precio = numeroPrecio.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;*/
    },
    async pedidoModificado() {
      await this.getPedidos(); //Se solicitan los pedidos para actualizar la tabla sin tener que recargar la view completa
      await this.obtenerArregloSucursalesDisponibles();
      // this.$emit("pedidoModificado");
    },
    getSiguienteEstado(pedido) {
      if (pedido.estado === "Revisión de crédito") {
        return this.ordenEstados[1]; //Si esta en revision de credito regresa el estado de Confirmacion
      }
      const estadoEncontrado = this.ordenEstados.find(
        (estado) => estado.text === pedido.estado
      );

      if (estadoEncontrado !== undefined) {
        const indice = this.ordenEstados.indexOf(estadoEncontrado);
        return this.ordenEstados[indice + 1];
      }
    },
    async avanzarRutaEntrega() {
      try {
        const response = await axios.post(
          `/pedidos/avanzar_pedido?id_pedido=${this.pedidoEnRuta.id}&nuevo_estado=en_ruta_de_entrega&mensajero_id=${this.mensajero}` //this.mensajero contiene solo el id del mensajero
        );
        console.log(response.data);
        alert("Pedido avanzado");
        // location.reload();
        await this.getPedidos(); //Se solicitan los pedidos para actualizar la tabla sin tener que recargar toda la view completa
        await this.obtenerArregloSucursalesDisponibles();
        this.$emit("pedidoModificado");
        this.dialogUsuariosGPS = false;
      } catch (error) {
        console.error(error.response);
        alert(error.response.data.detail[0].msg);
        this.dialogUsuariosGPS = false;
      }
    },
    async avanzarPedido(pedido) {
      const nuevoEstado = this.getSiguienteEstado(pedido);
      if (pedido.estado === "Revisión de crédito") {
        try {
          const response = await axios.post(
            `/pedidos/avanzar_pedido?id_pedido=${pedido.id}&nuevo_estado=confirmacion`
          );
          console.log(response.data);
          alert("Pedido avanzado");
          // location.reload();
          //Se solicitan los pedidos para actualizar la tabla sin tener que recargar toda la view completa
          await this.getPedidos();
          await this.obtenerArregloSucursalesDisponibles();
          if (nuevoEstado.value === "entregado") {
            this.$emit("pedidoEntregado");
          }
          this.dialogConfirmarAvanzar = false;
          // this.$emit("pedidoModificado");
        } catch (error) {
          if (error.response.status === 401) {
            console.error(error.response);
            alert("No permitido\n" + error.response.data.detail);
          } else {
            console.error(error.response);
            alert("Error al avanzar el pedido\n" + error.response.data.detail);
          }
          this.dialogConfirmarAvanzar = false;
        }
      }
      //Si el siguiente estado del pedido es en_ruta_de_entrega, se abre un dialogo para seleccionar el mensajero
      else if (nuevoEstado.value == "en_ruta_de_entrega") {
        this.pedidoEnRuta = pedido;

        // Utilizar el método filter para obtener un nuevo array con usuarios cuya sucursal sea la del pedido
        const mensajerosArray = this.usuarios.filter(
          (usuario) =>
            usuario.sucursal === pedido.sucursal && usuario.gps !== null
        );
        console.log(mensajerosArray);

        this.mensajeros = mensajerosArray.map((mensajero) => ({
          text: mensajero.nombre,
          value: mensajero.id,
        }));

        this.dialogConfirmarAvanzar = false;
        this.dialogUsuariosGPS = true;
      } else {
        try {
          const response = await axios.post(
            `/pedidos/avanzar_pedido?id_pedido=${pedido.id}&nuevo_estado=${nuevoEstado.value}`
          );
          console.log(response.data);
          alert("Pedido avanzado");
          // location.reload();
          //Se solicitan los pedidos para actualizar la tabla sin tener que recargar toda la view completa
          await this.getPedidos();
          await this.obtenerArregloSucursalesDisponibles();
          if (nuevoEstado.value === "entregado") {
            this.$emit("pedidoEntregado");
          }
          this.dialogConfirmarAvanzar = false;
          // this.$emit("pedidoModificado");
        } catch (error) {
          if (error.response.status === 401) {
            console.error(error.response);
            alert("No permitido\n" + error.response.data.detail);
          } else {
            console.error(error.response);
            alert(error.response.data.detail);
          }
          this.dialogConfirmarAvanzar = false;
        }
      }
    },
    crearListaDiscrepancias(stringDiscrepancias) {
      const discrepanciasList = stringDiscrepancias.split(",");
      return discrepanciasList;
    },
    obtenerArregloSucursalesDisponibles() {
      const nuevoArreglo = this.itemsPedidos.sort((a, b) => {
        const indiceA = this.ordenSucursales.indexOf(a.sucursal);
        const indiceB = this.ordenSucursales.indexOf(b.sucursal);
        return indiceA - indiceB;
      });
      this.itemsSucursalesDisponibles = nuevoArreglo;
    },
    getNombreVendedor(vendedorId) {
      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === vendedorId
      );
      if (vendedorEncontrado) {
        return vendedorEncontrado.nombre + " ";
      }
      return "";
    },
    getNombreCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        return clienteEncontrado.razon_social + " ";
      }
      return "";
    },
    getZonaCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        if (clienteEncontrado.zona) {
          return clienteEncontrado.zona + " ";
        }
      }
      return "";
    },
    /**Busca el cliente por id en el arreglo de clientes y retorna su informacion */
    getInfoCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        return clienteEncontrado;
      }
      return null;
    },
    getAntiguedad(fecha_creacion) {
      const now = new Date();
      const fecha = new Date(fecha_creacion);
      const diffMs = now - fecha;
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const diffHrs = Math.floor(
        (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      if (diffDays > 0) {
        return `${diffDays} día${diffDays > 1 ? "s" : ""}`;
      } else if (diffHrs > 0) {
        return `${diffHrs} hora${diffHrs > 1 ? "s" : ""}`;
      } else if (diffMins > 0) {
        return `${diffMins} minuto${diffMins > 1 ? "s" : ""}`;
      } else {
        return `menos de un minuto`;
      }
    },
    async getPedidos() {
      try {
        const response = await axios.get(`/pedidos/get_abiertos`);
        this.itemsPedidos = response.data;
        this.$emit("pedidosActualizados", this.itemsPedidos); //Se envian los pedidos actualizados a la vista de PedidosView
      } catch (error) {
        console.error(error);
      }
    },
    formatoFecha(fechaOriginal) {
      // Fecha en el formato original

      // Crear un objeto Date a partir de la fecha original
      const fecha = new Date(fechaOriginal);

      // Obtener los componentes de la fecha
      const year = fecha.getFullYear();
      const month = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agrega un cero inicial si es necesario
      const day = ("0" + fecha.getDate()).slice(-2); // Agrega un cero inicial si es necesario

      // Construir la fecha en el formato deseado
      const fechaFormateada = `${day}-${month}-${year}`;

      return fechaFormateada; // Resultado: '01-06-2023'
    },
    getFechaLegible(fechaOriginal) {
      const fecha = new Date(fechaOriginal);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible} ${horaLegible}`;
    },
    async getComentariosNuevos(periodo) {
      //formato de fecha: '2023-12-06 10:38:33-08'
      const currentDate = new Date();
      // console.log(currentDate);
      let fechaComentarios = "";
      // fechaComentarios = format(currentDate, "yyyy-MM-dd HH:mm:ssXX");

      switch (periodo) {
        case 1:
          fechaComentarios = format(
            subMinutes(currentDate, 15),
            "yyyy-MM-dd HH:mm:ssXX"
          );
          break;
        case 2:
          fechaComentarios = format(
            subHours(currentDate, 1),
            "yyyy-MM-dd HH:mm:ssXX"
          );
          break;
        case 3:
          fechaComentarios = format(
            subHours(currentDate, 4),
            "yyyy-MM-dd HH:mm:ssXX"
          );
          break;
        case 4:
          fechaComentarios = format(
            subHours(currentDate, 8),
            "yyyy-MM-dd HH:mm:ssXX"
          );
          break;
        default:
          fechaComentarios = format(currentDate, "yyyy-MM-dd HH:mm:ssXX");
          break;
      }

      // console.log(fechaComentarios);
      // fechaComentarios = '2023-12-06 10:38:33-08';

      try {
        const response = await axios.get(
          `/varios/get_comentarios_nuevos?tipo_objeto=pedido&fecha=${fechaComentarios}`
        );
        // console.log(response.data);
        this.arrayComentariosNuevos = response.data;
        this.dialogComentarios = false;
      } catch (error) {
        console.error(error);
        this.dialogComentarios = false;
      }
    },
    verCantidadPedidos() {
      // console.log(this.selectedItems);

      const estados = [
        "Pedido creado",
        "Revisión de crédito",
        "Confirmación",
        "Facturación",
        "Preparación en almacén",
        "Preparado",
        "En ruta de entrega",
      ];

      const contadores = estados.map((estado) => {
        return this.selectedItems.reduce((acumulador, pedido) => {
          return acumulador + (pedido.estado === estado ? 1 : 0);
        }, 0);
      });

      this.chartSeries = [
        {
          name: "Cantidad",
          data: contadores,
        },
      ];

      // console.log(contadores);
      this.dialogPedidos = true;
    },
  },
  computed: {
    ...mapGetters(["user", "currentUser"]),
    getFilteredUsuarios() {
      return this.usuarios.filter((user) => {
        return user.nombre_adminpaq !== null;
      });
    },
    // Obtiene el día actual de la semana
    currentDay() {
      const days = [
        "DOMINGO",
        "LUNES",
        "MARTES",
        "MIERCOLES",
        "JUEVES",
        "VIERNES",
        "SABADO",
      ];
      const today = new Date();
      return days[today.getDay()];
    },
    todosMensajerosSeleccionados() {
      // Verifica si todos los pedidos tienen un nuevo mensajero seleccionado
      const seleccionados = this.pedidosConEstadoSolicitud.every(
        (pedido) =>
          pedido.nuevoMensajero !== null && pedido.nuevoMensajero !== undefined
      );
      // console.log(seleccionados);
      return seleccionados;
    },
    itemsNombre() {
      return this.itemsSucursalesDisponibles.map((sucursal) => {
        const pedidos = sucursal.pedidos.map((pedido) => {
          const nuevoPedido = { ...pedido };
          const infoCliente = this.getInfoCliente(pedido.cliente); //se almacena la informacion del cliente
          nuevoPedido.vendedor = this.getNombreVendedor(pedido.vendedor);
          nuevoPedido.creado_por = this.getNombreVendedor(pedido.creado_por);
          nuevoPedido.mensajero = this.getNombreVendedor(pedido.mensajero);
          nuevoPedido.idCliente = pedido.cliente;
          // nuevoPedido.cliente = this.getNombreCliente(pedido.cliente);
          // nuevoPedido.zona = this.getZonaCliente(pedido.cliente);

          if (infoCliente) {
            nuevoPedido.cliente = infoCliente.razon_social + " ";
            if (infoCliente.zona) {
              nuevoPedido.zona = infoCliente.zona + " ";
            } else {
              nuevoPedido.zona = "";
            }
          } else {
            nuevoPedido.cliente = "";
            nuevoPedido.zona = "";
          }

          if (pedido.facturas) {
            if (pedido.facturas[0] === null) {
              nuevoPedido.facturas = [];
            }
          } else {
            nuevoPedido.facturas = [];
          }

          // if (pedido.serie_factura && pedido.folio_factura) {
          //   nuevoPedido.serie_folio =
          //     pedido.serie_factura + " " + pedido.folio_factura;
          // }
          if (pedido.discrepancias === null) {
            nuevoPedido.discrepancias = "Sin discrepancias";
          }
          if (pedido.contado) {
            nuevoPedido.pago = "Contado";
          } else {
            nuevoPedido.pago = "Crédito";
          }
          let estado = pedido.estado;
          switch (estado) {
            case "pedido_creado":
              estado = "Pedido creado";
              break;
            case "revision_de_credito":
              estado = "Revisión de crédito";
              break;
            case "confirmacion":
              estado = "Confirmación";
              break;
            case "facturacion":
              estado = "Facturación";
              break;
            case "preparacion_en_almacen":
              estado = "Preparación en almacén";
              break;
            case "preparado":
              estado = "Preparado";
              break;
            case "en_ruta_de_entrega":
              estado = "En ruta de entrega";
              break;
            case "entregado":
              estado = "Entregado";
              break;
            case "cancelado":
              estado = "Cancelado";
              break;
            default:
          }
          nuevoPedido.estado = estado;
          return nuevoPedido;
        });

        //Para colocar los pedidos con prioridad primero
        // Usamos sort() junto con un comparador personalizado
        pedidos.sort((a, b) => {
          // Primero, verificamos si 'a' tiene prioridad y 'b' no
          if (a.prioridad && !b.prioridad) {
            return -1; // 'a' va antes que 'b'
          }

          // Segundo, verificamos si 'b' tiene prioridad y 'a' no
          if (!a.prioridad && b.prioridad) {
            return 1; // 'b' va antes que 'a'
          }

          // En caso de que ambos tengan la misma prioridad o ambos no tengan prioridad,
          // se mantiene el orden actual.
          return 0;
        });

        // console.log(pedidos);
        return { ...sucursal, pedidos };
      });
    },
    selectedItems() {
      this.filtrarItems();
      if (this.selectedTab >= 1 && this.selectedTab <= 6) {
        return this.itemsNombre[this.selectedTab].pedidos;
      } else {
        return this.itemsNombre[this.selectedTab].pedidos || [];
      }
    },
    itemRowBackgroundPrioridad() {
      return (item) => {
        const estado = item.estado;
        const prioridad = item.prioridad;
        let classes = "";

        switch (estado) {
          case "Cancelado":
            classes = "red lighten-4";
            break;
          case "Revisión de crédito":
            classes = "deep-purple lighten-4";
            break;
          case "Facturación":
            classes = "teal lighten-4";
            break;
          case "En ruta de entrega":
            classes = "yellow lighten-4";
            break;
          case "Preparación en almacén":
            classes = "orange lighten-4";
            break;
          case "Preparado":
            classes = "blue lighten-4";
            break;
          case "Pedido creado":
            classes = "green lighten-4";
            break;
          case "Confirmación":
            return "light-green lighten-4";
          case "Entregado":
            classes = "indigo lighten-4";
            break;
          default:
            classes = "";
            break;
        }

        if (prioridad) {
          classes += " highlighted-row";
        }

        return classes;
      };
    },
    itemRowBackground() {
      return (item) => {
        const estado = item.estado;
        switch (estado) {
          case "Cancelado":
            return "red lighten-4";
          case "Facturación":
            return "teal lighten-4";
          case "Revisión de crédito":
            return "deep-purple lighten-4";
          case "En ruta de entrega":
            return "yellow lighten-4";
          case "Preparación en almacén":
            return "orange lighten-4";
          case "Preparado":
            return "blue lighten-4";
          case "Pedido creado":
            return "green lighten-4";
          case "Confirmación":
            return "light-green lighten-4";
          case "Entregado":
            return "indigo lighten-4";
          default:
            return "";
        }
      };
    },
  },
  watch: {
    selectedTab() {
      if (this.selectedPedidos.length > 0) {
        this.selectedPedidos = [];
      }
    },
  },
  mounted() {
    this.obtenerArregloSucursalesDisponibles();
    this.getComentariosNuevos();
    //console.log(this.formatoPrecio(10000));
    this.obtenerMensajeroConGps();
  },
  async created() {
    // Establece un temporizador para llamar a getPedidos() cada cierto intervalo
    this.timer = setInterval(async () => {
      await this.getPedidos();
      await this.obtenerArregloSucursalesDisponibles();
    }, 15000); // 15000 milisegundos = 15 segundos
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  components: { AccionesPedido, apexchart: VueApexCharts },
};
</script>

<style>
.highlighted-row {
  outline: 2px solid rgb(237, 97, 97); /* Cambie el color y el estilo del borde según sus necesidades */
  box-shadow: inset 2px 0 0 rgb(237, 97, 97), inset -2px 0 0 rgb(237, 97, 97); /* Ajuste el color y tamaño según sus necesidades */
}
</style>
